import moment from 'moment';
import i18next from "i18next";

export const FormatedDate = (data, format) => {
    return moment(data).format(format).toString()
}

export const FormatedStr = (str, padstart, char, prefix) => {
    return `${prefix ? prefix : ''}${String(str).padStart(padstart, char)}`;
}

export const convertMillisToTime = (ms, delim = ":") => {
    const showWith0 = value => (value < 10 ? `0${value}` : value);
    const hours = showWith0(Math.floor((ms / (1000 * 60 * 60)) % 60));
    const minutes = showWith0(Math.floor((ms / (1000 * 60)) % 60));
    const seconds = showWith0(Math.floor((ms / 1000) % 60));
    return `${parseInt(hours) ? `${hours}${delim}` : ""}${minutes}${delim}${seconds}`;
}
import authService from '../api-authorization/AuthorizeService';
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants';

export const AuthorizeFunction = async (callBack, roles) => {
    if (await authService.isAuthorized(roles)) {
        callBack();
    }
    else {
        if (await authService.isAuthenticated()) return;
        window.location.href = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
    }
}
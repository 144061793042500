import React, { useContext, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TeamRanking from './TeamRanking';
import PlayerRanking from './PlayerRanking';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    tabs: {
        marginBottom: theme.spacing(2),
    },
    avatarCaption: {
        fontSize: '60%',
        fontStyle: 'italic',
    },
    title: {
        fontSize: '110%',
    },
    subtitle: {
        paddingTop: -3,
        marginTop: -3,
        fontSize: '85%',
        fontStyle: 'italic',
    },
    smallAvatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
    },
}));

const Statistics = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const classes = useStyles();
    const { setLeagueID } = props;
    const { leagueID, ranking } = props.match.params;

    useEffect(() => {
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:statistics' } });
        return () => {
        }
    }, []);

    return (
        <>
            <Tabs
                value={ranking}
                variant={"scrollable"}
                className={classes.tabs}
            >
                <Tab
                    value={'team'}
                    label={t('common:teams')}
                    component={Link}
                    to={`/home/statistics/${leagueID}/team`}
                />
                <Tab
                    value={'player'}
                    label={t('common:players')}
                    component={Link}
                    to={`/home/statistics/${leagueID}/player`}
                />
            </Tabs >

            <Route exact path='/home/statistics/:leagueID/team' render={(props) =>
                <TeamRanking {...props} setLeagueID={setLeagueID} />
            } />

            <Route exact path='/home/statistics/:leagueID/player' render={(props) =>
                <PlayerRanking {...props} setLeagueID={setLeagueID} />
            } />
        </>
    )
}
export default Statistics

import React from 'react'
import { useState, useEffect, useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from '../api-authorization/ApiAuthorizationConstants'
import authService from '../api-authorization/AuthorizeService';

const AuthorizeRoute = (props) => {
    const {roles, exact, path} = props;
    const [isReady, setIsReady] = useState(false);
    const [isAuthentificate, setIsAuthentificate] = useState(false);
    const [isAuthorize, setIsAuthorize] = useState(false);

    useEffect(() => {
        const init = async () => {
            let [isAuthorised, isAuthentificated] = await Promise.all([authService.isAuthorized(roles), authService.isAuthenticated()])
            setIsAuthorize(isAuthorised);
            setIsAuthentificate(isAuthentificated);
            setIsReady(true);
        };
        init();
        return () => {
        }
    }, []);

    var link = document.createElement("a");
    link.href = path;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURIComponent(returnUrl)}`;

    if (!isReady) {
        return <></>;
    } else {
        const { component: Component, noAccess, ...rest } = props;
        return <Route exact={exact} {...rest}
            render={(props) => {
                if (isAuthentificate) {
                    if (isAuthorize) {
                        return <Component {...props} />
                    }
                    else {
                        return noAccess ? <div>no access</div> : <></>
                    }
                } else {
                    return <Redirect to={redirectUrl} />
                }
            }} />
    }
}
export default AuthorizeRoute;
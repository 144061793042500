import React, { useContext, useEffect, useRef } from 'react';
import useDimensions from 'react-use-dimensions';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';

import Loader from '../_shared/Loader';
import { appStore } from '../../appStore.js';

const ScrollBody = (props) => {
    const { isLoadingNext, callbackNext, hasMoreNext, children, isLoadingPrevious, callbackPrevious, hasMorePrevious, scrollTop } = props;
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const [ref, size] = useDimensions();
    const height = state.height - size.y  - 12;

    useEffect(() => {
        if (!scrollTop) return;
        document.getElementById('scrollContainer').scrollTop = scrollTop;
    }, [height]);

    const handleOnScroll = async (e) => {
        const target = e.currentTarget;

        if (Math.round(target.scrollTop) === 0 && hasMoreNext) {
            if (isLoadingPrevious || !callbackNext) return;
            callbackNext();
        }
        if ((Math.round(target.scrollHeight) - Math.round(target.clientHeight) - Math.round(target.scrollTop)) < 50 && hasMorePrevious) {
            if (isLoadingPrevious || !callbackPrevious) return;
            callbackPrevious();
        }
    }

    return (
        <Container
            ref={ref}
            style={{ height: `${height}px`, display: 'block', overflowY: 'auto', margin: '6px', overflowX: 'auto' }}
            onScroll={handleOnScroll}
            id={'scrollContainer'}
            className={props.className}
        >
            <Loader isLoading={isLoadingNext && !isLoadingPrevious} >
                {
                    //has more previous
                    hasMoreNext && !isLoadingNext ?

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="small"
                            onClick={callbackNext}
                        >
                            {t('common:hasMore')}
                        </Button>
                        : ''
                }
            </Loader>
            {
                //main
                children
            }
            <Loader isLoading={isLoadingPrevious}>
                {
                    //has more next
                    hasMorePrevious && !isLoadingPrevious ?

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            size="small"
                            onClick={callbackPrevious}
                        >
                            {t('common:hasMore')}
                        </Button>
                        : ''
                }
            </Loader>
        </Container>
    )
}
export default ScrollBody

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color'

import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
    color: {
        background: props => props.color,
        height: theme.spacing(3),
        width: theme.spacing(3),
    },
}));

const ColorPicker = (props) => {
    const classes = useStyles(props);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null)
    };

    const handleChange = (color) => {
        props.onChange(props.propName, color.hex);
        setAnchorEl(null);
    };

    return (
        <>
            <Avatar className={classes.color} onClick={handleClick}>
                {' '}
            </Avatar>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <SketchPicker color={props.color} onChange={handleChange} />
                </MenuItem>
            </Menu>

        </>
    )
}
export default ColorPicker

import { useState, useEffect } from 'react';
import update from 'immutability-helper';
import isEqual from 'lodash.isequal';

const useForm = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(props ? props : null);
    const [errors, setErrors] = useState([]);
    const [hasChanges, setHasChanges] = useState(false);
    const [prev, setPrev] = useState(null);

    useEffect(() => {
        setHasChanges(!isEqual(prev, data));
    }, [data, prev]);

    const updateProp = (prop, value, prop2, value2) => {
        let updated = update(data, { [prop]: { $set: isNaN(value) ? value : (value === false || value === true) ? value === true : value === '' ? '' : Number(value) } });

        if (prop2) {
            updated = update(updated, { [prop2]: { $set: isNaN(value2) ? value2 : (value2 === false || value2 === true) ? value2 === true : value2 === '' ? '' : Number(value2) } });
        }
        setData(updated);
    }

    return [isLoading, setIsLoading, data, setData, errors, setErrors, updateProp, hasChanges, setHasChanges, prev, setPrev];
};
export default useForm;
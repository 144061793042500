import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import i18next from "i18next";

import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';

import Loader from '../_shared/Loader';
import useList from '../hooks/useList';
import httpClient from '../_shared/HttpClient';

//store
import { appStore } from '../../appStore.js';
import { Typography, ListItemAvatar, Avatar } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    okButton: {
        color: theme.palette.info.main,
    },
}));

const UserClaims = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = props;
    const { state, dispatch } = useContext(appStore);
    const [isLoading, setIsLoading, rows, setRows, hasMore, setHasMore, upsertRow, removeRow] = useList('name');
    const [selected, setSelected] = useState(null);

    const take = 10;
    const skip = rows ? rows.length : 0;

    useEffect(() => {
        loadData();
    }, [id]);

    const loadData = async (append) => {
        if (isLoading || (!hasMore && append) || !id) return;
        setIsLoading(true);

        if (!append) {
            setRows([]);
        }
        try {
            var result = await httpClient('get', 'user/GetUserClaims', { skip: skip, take: take, userID: id });

            if (append) {
                setRows([...rows, ...result])
            }
            else {
                setRows(result);
            }
            setHasMore(false);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleDelete = async (e, i, row, userID) => {
        e.preventDefault();

        if (isLoading) return;

        try {
            setIsLoading(true);
            console.log(row);
            const result = await httpClient('delete', 'User/RemoveUserClaim', { claimObject: row.claimObject, value: row.value, userId: row.userId });
            console.log(result);
            if (result.errors) {
                // setErrors(result.errors);
            }
            else {
                removeRow(i);
            }
        }
        catch (e) {
            // setErrors([{ message: e }]);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <Loader isLoading={isLoading}>
            <Container>
                {
                    (rows ? rows : []).map((x, i) =>
                        <List
                            key={i}
                            component="nav"
                        >
                            <ListItem
                                button
                                divider
                                onClick={() => setSelected(i)}
                            >

                                <ListItemAvatar>
                                    <Avatar
                                        src={x.picture}
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={
                                        <Typography noWrap>
                                            {x.description}
                                        </Typography>
                                    }
                                    secondary={x.claimType}
                                />
                                <ListItemSecondaryAction onClick={(e) => handleDelete(e, i, x, x.userID)}>
                                    <DeleteIcon
                                        edge="end"
                                    />
                                </ListItemSecondaryAction>
                            </ListItem>
                        </List>
                    )
                }
            </Container>
        </Loader >

    )
}
export default UserClaims

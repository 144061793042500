import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'lodash.isequal';
import { SketchPicker } from 'react-color'

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import OkIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';

import ColorPicker from '../_shared/ColorPicker';
import Loader from '../_shared/Loader';
import useForm from '../hooks/useForm';
import httpClient from '../_shared/HttpClient';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        // backgroundColor: fade(theme.palette.primary.light, .1),
        // borderRadius: theme.spacing(1),
    },
    okButton: {
        color: theme.palette.info.main,
    },
    cancelButton: {
        color: theme.palette.error.main,
    },
    title: {
        flexGrow: 1,
        fontFamily: 'Comic Sans MS, cursive, sans-serif',
        marginLeft: theme.spacing(1),
    },
}));

const VideoStatsReport = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const classes = useStyles();
    const { id, month } = props;
    const [isLoading, setIsLoading, row, setRow, errors, setErrors, updateProp] = useForm();
    const [hasChanges, setHasChanges] = useState(false);
    const [prev, setPrev] = useState(null);

    useEffect(() => {
        loadSport();
        return () => {
        }
    }, [id]);

    useEffect(() => {
        setHasChanges(!isEqual(prev, row));
    }, [row, prev]);

    const loadSport = async () => {
        try {
            setIsLoading(true);
            var row = await httpClient('get', 'league/GetVideoStatsReport', { id: id, month: month });
            setRow(row);
            setPrev(row);

            //set nav title
            props.setTitle(id === '0' ? `${t('actions:add')} ${t('common:league')}` : `${t('actions:edit')} ${row.name}`);
        }
        catch (e) {
            setErrors([{ message: e }]);
        }
        finally {
            setIsLoading(false);
        }
    };

   



    return (
        <Loader isLoading={isLoading}>
            <Container className={classes.root}>

               
                    {
                        !row ? '' :
                            <>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth={true}
                                        variant={'outlined'}
                                        label={t('common:start')}
                                        type="text"
                                        value={row.debut ? row.debut : ''}
                                       
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth={true}
                                        variant={'outlined'}
                                        label={t('common:end')}
                                        type="text"
                                        value={row.fin ? row.fin : ''}
                                       
                                    />
                                </Grid>


                            
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                        fullWidth={true}
                                        variant={'outlined'}
                                        label={t('common:gameNumber')}
                                        type="text"
                                        value={row.nbGames}
                                          
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                        fullWidth={true}
                                        variant={'outlined'}
                                        label={t('common:videoNumber')}
                                        type="text"
                                        value={row.nbVideos}
                                           
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                        fullWidth={true}
                                        variant={'outlined'}
                                        label={t('common:ipNumber')}
                                        type="text"
                                        value={row.nbIp}
                                            
                                        />
                                    </Grid>

                            </Grid>



                                
                            </>
                    }
               
            </Container>
        </Loader >
    )
}
export default VideoStatsReport

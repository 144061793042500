import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import i18next from "i18next";
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import 'moment/locale/fr';



import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';


import ScrollBody from '../_shared/ScrollBody';
import httpClient from '../_shared/HttpClient';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    topText: {
        fontSize: '70%',
        margin: 0,
    },
    middleText: {
        fontSize: '85%',
        margin: 0,
    },
    bottomText: {
        fontSize: '70%',
        marginTop: 0,
        marginBottom: 12,
    },
    winScoreBox: {
        background: theme.palette.primary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    scoreBox: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    guestTeamName: {
        textAlign: props => props.isMobile ? 'Left' : 'right',
        marginRight: props => props.isMobile ? '' : theme.spacing(1),
    },
}));

const Calendar = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const classes = useStyles(state);
    const { leagueID } = props.match.params;
    const { previousGames, nextGames } = state;
    const [nextGamesLoading, setNextGamesLoading] = useState(false);
    const [nextGamesHasMore, setNextGamesHasMore] = useState(false);
    const [previousGamesLoading, setPreviousGamesLoading] = useState(false);
    const [previousGamesHasMore, setPreviousGamesHasMore] = useState(false);

    const games = [...nextGames, ...previousGames]

    const take = 10;
    const skipNextGames = nextGames ? nextGames.length : 0;
    const skipPreviousGames = previousGames ? previousGames.length : 0;

    useEffect(() => {
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:calendar' } });
        return () => {
        }
    }, []);

    useEffect(() => {       
        //doesnt call api if league is same and some games where loaded previously
        ////////
        if ((previousGames.length > 0 || nextGamesHasMore.length > 0) && leagueID == state.league.leagueID) return;
        props.setLeagueID(leagueID);
        loadNextGames();
        loadPreviousGames();
    }, [leagueID]);

    const loadNextGames = async () => {
        if (nextGamesLoading) return;
        try {
            setNextGamesLoading(true);
            var result = await httpClient('get', 'Calendar/GetNextGames', { skip: skipNextGames, take: take, leagueID: leagueID });
            dispatch({ type: 'updateNextGames', payload: { games: [...result, ...nextGames] } });
            setNextGamesHasMore(result.length === take);
        }
        catch (err) {
            console.log(err);
            setNextGamesHasMore(false);
        }
        setNextGamesLoading(false);
    };

    const loadPreviousGames = async () => {
        if (previousGamesLoading) return;
        try {
            setPreviousGamesLoading(true);
            var result = await httpClient('get', 'Calendar/GetPreviousGames', { skip: skipPreviousGames, take: take, leagueID: leagueID });
            dispatch({ type: 'updatePreviousGames', payload: { games: [...previousGames, ...result] } });
            setPreviousGamesHasMore(result.length === take);
        }
        catch (err) {
            console.log(err);
            setPreviousGamesHasMore(false);
        }
        finally {
            setPreviousGamesLoading(false);
        }
    };

    const handleGameClick = () => {
        var element = document.getElementById('scrollContainer');
        dispatch({ type: 'updateCalendarScrollTop', payload: { scrollTop: element.scrollTop } })
    }

    return (
        <ScrollBody
            callbackNext={loadNextGames}
            isLoadingNext={nextGamesLoading}
            hasMoreNext={nextGamesHasMore}
            callbackPrevious={loadPreviousGames}
            isLoadingPrevious={previousGamesLoading}
            hasMorePrevious={previousGamesHasMore}
            scrollTop={state.calendarScrollTop}
        >
            <List
                component="nav"
            >
                {
                    games.map((x, i) =>
                        <ListItem
                            key={i}
                            divider
                            component={Link}
                            to={x.hasEvents ? `/home/calendar/${leagueID}/summary/${x.gameID}` : `/home/calendar/${leagueID}`}
                            onClick={handleGameClick}
                        >
                            <ListItemText>

                                <div className={classes.topText}>
                                    {x.seasonDescription}
                                </div>
                                <div className={classes.middleText}>
                                    <Moment locale={i18next.language} format="ddd Do MMMM YYYY - HH:mm" date={x.gameDateTime} />
                                </div>
                                <div className={classes.bottomText}>
                                    {x.locationDescription}
                                </div>

                                <Grid
                                    container
                                    spacing={2}
                                >

                                    <Grid
                                        item xs={12}
                                        sm={6}>

                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item xs={'auto'}>
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt={x.homeTeam.name}
                                                        src={x.homeTeam.logoUrl} />
                                                </ListItemAvatar>
                                            </Grid>

                                            <Grid item xs>
                                                {x.homeTeam.name}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={'auto'}
                                            >
                                                <ListItemAvatar>
                                                    {
                                                        x.homeTeamWins && x.lastVictoriousEvent ?
                                                            <Badge badgeContent={JSON.parse(x.lastVictoriousEvent)[i18next.languages[0]]} color="secondary">
                                                                <Avatar className={classes.winScoreBox}>
                                                                    {x.homeScore}
                                                                </Avatar>
                                                            </Badge>
                                                            :
                                                            <Avatar className={x.homeTeamWins ? classes.winScoreBox : classes.scoreBox}>
                                                                {x.homeScore}
                                                            </Avatar>
                                                    }
                                                </ListItemAvatar>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>

                                        <Grid
                                            container
                                            alignItems="center"
                                            direction={state.isMobile ? "row" : "row-reverse"}
                                            spacing={1}
                                        >
                                            <Grid item xs={'auto'}>
                                                <ListItemAvatar>
                                                    <Avatar alt={x.guestTeam.name} src={x.guestTeam.logoUrl} />
                                                </ListItemAvatar>
                                            </Grid>

                                            <Grid item xs className={classes.guestTeamName}>
                                                {x.guestTeam.name}
                                            </Grid>

                                            <Grid item xs={'auto'}>
                                                <ListItemAvatar>
                                                    {
                                                        x.guestTeamWins && x.lastVictoriousEvent ?
                                                            <Badge badgeContent={JSON.parse(x.lastVictoriousEvent)[i18next.language]} color="secondary">
                                                                <Avatar className={classes.winScoreBox}>
                                                                    {x.guestScore}
                                                                </Avatar>
                                                            </Badge>
                                                            :
                                                            <Avatar className={x.guestTeamWins ? classes.winScoreBox : classes.scoreBox}>
                                                                {x.guestScore}
                                                            </Avatar>
                                                    }
                                                </ListItemAvatar>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </ListItemText>
                        </ListItem>
                    )
                }

            </List >
        </ScrollBody>
    )
}
export default Calendar

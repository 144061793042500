import React, { useState, useEffect, useContext } from 'react';
import { Route, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import httpClient from '../_shared/HttpClient';
import useList from '../hooks/useList';
import SearchBox from '../_shared/SearchBox';
import ScrollBody from '../_shared/ScrollBody';

import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    avatar: {
        // width: props => theme.spacing(4),
        // height: props => theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    icon: {
        color: theme.palette.secondary.contrastText,
    },
}));

const LeagueList = (props) => {
    const classes = useStyles();
    const { state, dispatch } = useContext(appStore);
    const { t } = useTranslation();
    const [isLoading, setIsLoading, rows, setRows, hasMore, setHasMore, removeRow] = useList('name');
    const [searchStr, setSearchStr] = useState('');

    const take = 20;
    const skip = rows ? rows.length : 0;

    useEffect(() => {
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:leagues' } });
        return () => {
        }
    }, []);

    const loadData = async (searchStr, append) => {
        if (isLoading || (!hasMore && append)) return;
        setIsLoading(true);

        if (!append) {
            setRows([]);
        }
        try {
            var result = await httpClient('get', 'league/list', { skip: skip, take: take, searchStr: searchStr });
            if (append) {
                setRows([...rows, ...result])
            }
            else {
                setRows(result);
            }
            setHasMore(result.length === take);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Grid
                container
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={4} sm={8}>

                </Grid>

                <Grid item xs={8} sm={4}>

                    <SearchBox
                        searchStr={searchStr}
                        setSearchStr={setSearchStr}
                        callback={loadData}
                    />

                </Grid>
            </Grid>

            <ScrollBody callbackPrevious={() => loadData(searchStr, true)} isLoadingPrevious={isLoading} hasMorePrevious={hasMore}>

                <List>

                    {/* <ListItem
                        button
                        component={Link}
                        to={`leagues/edit/${0}`}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <AddIcon fontSize="small" className={classes.icon} />
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={t('actions:add')}
                        />
                    </ListItem> */}

                    {
                        rows.map((l, i) =>

                            <ListItem
                                key={i}
                                button
                                component="a"
                                href={"/home/calendar/" + l.leagueID}
                            >
                                <ListItemAvatar>
                                    <Avatar
                                        src={l.logoUrl}
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={
                                        <Typography noWrap>
                                            {l.name}
                                      </Typography>
                                    }
                                    secondary={l.city}
                                />

                                {
                                    l.canUserEdit ? <ListItemSecondaryAction>
                                        <IconButton
                                            component={Link}
                                            to={"/home/leagues/" + l.leagueID}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                        : ''
                                }


                            </ListItem>
                        )
                    }
                </List>
            </ScrollBody>
        </>
    )
}
export default LeagueList

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import i18next from "i18next";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import 'moment/locale/fr';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';

import httpClient from '../_shared/HttpClient';
import ScrollBody from '../_shared/ScrollBody';
import Loader from '../_shared/Loader';
import { convertMillisToTime } from '../_shared/StringFormat';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    tabs: {
        marginBottom: theme.spacing(1),
    },
    avatarCaption: {
        fontSize: '60%',
        fontStyle: 'italic',
    },
    title: {
        fontSize: '110%',
    },
    subtitle: {
        paddingTop: -3,
        marginTop: -3,
        fontSize: '85%',
        fontStyle: 'italic',
    },
    smallAvatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
    },
}));

const Summary = (props) => {
    const { state, dispatch } = useContext(appStore);
    const { leagueID, gameID } = props.match.params;
    const { t } = useTranslation();
    const classes = useStyles(state);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:summary' }});
        return () => {
        }
    }, []);

    useEffect(() => {
        props.setLeagueID(leagueID);
        if (!gameID) return;
        loadData();
    }, [leagueID, gameID]);

    const loadData = async () => {
        try {
            setIsLoading(true);
            var result = await httpClient('get', 'Calendar/GetSummaryAndEvents', { Id: gameID });
            setData(result);
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader isLoading={isLoading}><div></div></Loader>
    }

    return (
        <>
            <ListItem button dense disableGutters divider component={Link} to={"/home/calendar/" + leagueID} onClick={() => dispatch({ type: 'updateSummaryIndex', payload: { index: 0 } })}>
                <ListItemAvatar>
                    <Avatar className={classes.smallAvatar}>
                        <BackIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={data.title}
                    secondary={
                        <Moment locale={i18next.language} format="ddd Do MMMM YYYY - HH:mm" date={data.gameDateTime} />
                    } />
            </ListItem>

            <Tabs
                value={state.summaryIndex}
                onChange={(e, v) => dispatch({ type: 'updateSummaryIndex', payload: { index: v } })}
                variant={"scrollable"}
                className={classes.tabs}
            >
                {
                    (data && data.eventTypes ? data.eventTypes : []).map((x, i) =>
                        <Tab
                            key={i}
                            label={JSON.parse(x.name)[i18next.languages[0]]}
                        />
                    )
                }
            </Tabs >

            <ScrollBody
                hasMorePrevious={isLoading}
                hasMoreNext={false}
            >
                {
                    (data && data.eventTypes ? data.eventTypes : []).map((x, i) =>
                        <div key={'events_' + i}
                            hidden={i !== state.summaryIndex}
                        >
                            {
                                (x.events ? x.events : []).map((e, index) =>

                                    <ListItem
                                        key={e.eventID}
                                        component={Link}
                                        to={e.hasVideos ? `/home/calendar/${leagueID}/summary/${gameID}/event/${e.eventID}` : `/home/calendar/${leagueID}/summary/${gameID}`}
                                        button={e.hasVideos}
                                        divider>

                                        <Grid
                                            container
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item xs={3} sm={2} align={"center"}>

                                                <Avatar src={e.team ? e.team.logoUrl : ''} />

                                                <Typography noWrap variant={'caption'} className={classes.avatarCaption}>
                                                    {e.team ? e.team.name : ''}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs sm={7}>
                                                <Typography variant={'caption'}>
                                                    <div className={classes.title}>
                                                        {e.title}
                                                    </div>
                                                    {
                                                        x.showOnlyPlayer ? '' :
                                                            <>
                                                                <div className={classes.subtitle}>
                                                                    {e.subtitle ? JSON.parse(e.subtitle)[i18next.languages[0]] : ''}
                                                                </div>

                                                                <div className={classes.subtitle}>
                                                                    {e.details ? t('actions:assist') + e.details : ''}
                                                                </div>
                                                            </>
                                                    }
                                                </Typography>
                                            </Grid>

                                            {
                                                x.showOnlyPlayer ? '' :
                                                    <Grid item xs={'auto'} sm={3} >

                                                        <Chip
                                                            size={"small"}
                                                            avatar={
                                                                <Avatar>
                                                                    {e.period}
                                                                </Avatar>
                                                            }
                                                            label={convertMillisToTime(e.time)}
                                                        />
                                                    </Grid>
                                            }

                                        </Grid>
                                    </ListItem>
                                )
                            }
                        </div>
                    )
                }

            </ScrollBody>

        </>
    )
}
export default Summary

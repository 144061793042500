import React, { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import useDimensions from 'react-use-dimensions';
import { makeStyles } from '@material-ui/core/styles';

//UI
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import LanguageIcon from '@material-ui/icons/Language';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AdminIcon from '@material-ui/icons/Accessibility';
import InfoIcon from '@material-ui/icons/Info';

//Auth
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import AuthorizeComponent from './components/_shared/AuthorizeComponent';
import httpClient from './components/_shared/HttpClient';

//store
import { appStore } from './appStore.js';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  appBarSpacer: theme.mixins.toolbar,

  menuButton: {
    marginRight: theme.spacing(1),
    height: theme.spacing(5),
    width: theme.spacing(5),
  },
  userEmail: {
    marginRight: theme.spacing(1),
    fontSize: '80%',
  },
  grow: {
    flexGrow: 1,
  },
}));

const AppNavBar = (props) => {
  const { state, dispatch } = useContext(appStore);
  const { t } = useTranslation();
  const [ref, size] = useDimensions();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    dispatch({ type: 'updateHeight', payload: { top: size.height } });
    console.log(i18next.languages[0]);
    console.log(i18next);
  }, [size.height]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleLanguage = async () => {
    try {
      const result = await httpClient('post', 'language/SetLanguage?culture=' + (i18next.languages[0] === 'fr' ? 'en' : 'fr'), null);
      i18next.changeLanguage(result);

      console.log(i18next.languages[0]);
      console.log(i18next);
    }
    catch (err) {
      console.log(err)
    }
    //close
    handleMenuClose();
  }

  const AdminLink = () => AuthorizeComponent(['Admin']) ?
    <MenuItem component={Link} to={'/admin'} onClick={handleMenuClose}>
      <IconButton
      >
        <AdminIcon />
      </IconButton>
      <p>{t('common:admin')}</p>
    </MenuItem>
    : <div></div>

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >

      {
        state.user ?
          <div>
            <MenuItem component={Link} to={{ pathname: `${ApplicationPaths.Profile}`, state: { local: true } }}>
              <IconButton
                aria-haspopup="true"
              >
                <AccountCircle />
              </IconButton>
              <p>Profile</p>
            </MenuItem>

            <MenuItem component={Link} to={'/home/info'} onClick={handleMenuClose}>
              <IconButton
              >
                <InfoIcon />
              </IconButton>
              <p>{t('pages:info')}</p>
            </MenuItem>

            <AdminLink />

            <MenuItem onClick={toggleLanguage}>
              <IconButton
              >
                <LanguageIcon />
              </IconButton>
              <p>{t('common:changeLanguage')}</p>
            </MenuItem>

            <MenuItem component={Link} to={{ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } }}>
              <IconButton
              >
                <ExitToAppIcon />
              </IconButton>
              <p>{t('actions:logout')}</p>
            </MenuItem>
          </div>

          :

          <div>
            <MenuItem component={Link} to={{ pathname: `${ApplicationPaths.Login}`, state: { local: true } }}>
              <IconButton
                aria-label="login"
                aria-haspopup="true"
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <p>{t('actions:login')}</p>
            </MenuItem>

            <MenuItem component={Link} to={'/home/info'} onClick={handleMenuClose}>
              <IconButton
              >
                <InfoIcon />
              </IconButton>
              <p>{t('common:about')}</p>
            </MenuItem>

            <MenuItem onClick={toggleLanguage}>
              <IconButton
              >
                <LanguageIcon />
              </IconButton>
              <p>{t('common:changeLanguage')}</p>
            </MenuItem>
          </div>
      }


    </Menu>
  );

  return (
    <div className={classes.appBarSpacer} ref={ref}>
      <AppBar
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>

          <Avatar
            className={classes.menuButton}
            src={state.league && state.league.logoUrl ? state.league.logoUrl : "/images/thumbnail_logo.png"}
            onClick={() => window.location = '/'}
          />

          <Typography noWrap variant="subtitle1" className={classes.title}>
            {state.navigatingAdmin ? "Admin" : state.league ? state.league.name : "Syncstats"}
          </Typography>

          <div className={classes.grow} />


          <ButtonBase
            edge="end"
            aria-controls={menuId}
            aria-haspopup="true"
            disabled={state.isLoading}
            onClick={(handleProfileMenuOpen)}
          >
            <Typography noWrap variant="caption" className={classes.userEmail}>
              {state.user ? state.user.name : t('common:login')}
            </Typography>

            <Avatar
              title="profile picture"
              src={state.user && state.user.picture ? state.user.picture : null}
            >
              {
                state.isLoading ? <CircularProgress /> : <PersonIcon />
              }
            </Avatar>

          </ButtonBase>

        </Toolbar>
      </AppBar>
      {renderMenu}
    </div >
  );
}
export default AppNavBar

import React, { useState, useEffect, useContext } from 'react';
import i18next from "i18next";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/fr';
import update from 'immutability-helper';

import ButtonBase from '@material-ui/core/ButtonBase';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Badge from '@material-ui/core/Badge';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import EventCommentInput from './EventCommentInput';
import httpClient from '../_shared/HttpClient';
import Loader from '../_shared/Loader';
import { AuthorizeFunction } from '../_shared/AuthorizeFunction';
import objectToFormData from '../_shared/objectToFormData';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    reactionGrid: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    comment: {
        fontSize: '85%',
    },
    smallAvatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
    },
    badgeAvatar: {
        width: props => theme.spacing(3),
        height: props => theme.spacing(3),
    },
    smallItalic: {
        fontSize: '70%',
        fontStyle: 'italic',
    },
    badge: {
        marginLeft: props => theme.spacing(1),
    },
    list: {
        marginTop: props => theme.spacing(1),
    },
}));

const EventComments = (props) => {
    const { state, dispatch } = useContext(appStore);
    const { t } = useTranslation();
    const classes = useStyles(state);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const { eventID, leagueID } = props;

    useEffect(() => {
        loadData();
        return () => {
        }
    }, []);

    const addReaction = async (row, i) => {
        try {
            setIsLoading(true);
            const result = await httpClient('post', 'Calendar/AddReaction', objectToFormData(row));
            setData(update(data, { 'reactions': { $set: update(data.reactions, { [i]: { $set: result } }) } }));
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    }

    const addComment = (row) => {
        setData(update(data, { ['comments']: { $set: [row].concat(data.comments) } }));
    }

    const loadData = async () => {
        try {
            setIsLoading(true);
            var result = await httpClient('get', 'Calendar/GetEventComments', { id: eventID, leagueID: leagueID });
            setData(result);
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    return (
        <List
        className={classes.list}
            component={'nav'}
            subheader={
                <ListSubheader
                component="div"
                disableSticky
                disableGutters
                >
                        <ListItem>

                            <ListItemText
                                secondary={`${data ? data.viewCountCount : 0} ${t('common:views')} - ${(data ? data.comments.length : 0)} ${t('common:comments')}`}
                            />

                            <ListItemSecondaryAction>
                                <>
                                    {
                                        (data && data.reactions ? data.reactions : []).map((x, i) =>
                                            <ButtonBase
                                                key={i}
                                                onClick={async () => await AuthorizeFunction(() => addReaction({ eventID: eventID, ReactionID: x.reactionID, reactionCount: x.reactionCount, userReacted: x.userReacted, iconUrl: x.iconUrl }, i), null)}
                                            >
                                                <Badge
                                                    badgeContent={<div className={classes.smallItalic}>{x.reactionCount}</div>}
                                                    color={x.userReacted ? "primary" : "secondary"}
                                                    className={classes.badge}
                                                >
                                                    <Avatar className={classes.badgeAvatar} src={x.iconUrl} />
                                                </Badge>
                                            </ButtonBase>
                                        )
                                    }
                                </>
                            </ListItemSecondaryAction>

                        </ListItem>
                </ListSubheader>
            }
        >

            <EventCommentInput
                eventID={eventID}
                addComment={addComment}
            />

            {
                (data && data.comments ? data.comments : []).map((x, i) =>

                    <ListItem
                        key={i}
                        disableGutters={state.isMobile}
                    >

                        <ListItemAvatar>
                            <Avatar
                                alt={x.user ? x.user.userName : ''}
                                src={x.user && x.user.picture ? x.user.picture : ''}
                                className={classes.smallAvatar}
                            />
                        </ListItemAvatar>

                        <ListItemText
                            primary={x.description}
                            secondary={`${x.user.userName} | ${moment(x.createdAt).locale(i18next.language).fromNow()}`}
                        />
                    </ListItem>
                )
            }
        </List>
    )
}
export default EventComments

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import i18next from "i18next";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player'
import Moment from 'react-moment';
import 'moment/locale/fr';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Chip from '@material-ui/core/Chip';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import BackIcon from '@material-ui/icons/KeyboardArrowLeft';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/Button';

import httpClient from '../_shared/HttpClient';
import ScrollBody from '../_shared/ScrollBody';
import Loader from '../_shared/Loader';
import { convertMillisToTime } from '../_shared/StringFormat';
import EventComments from './EventComments';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    tabs: {
        marginBottom: theme.spacing(1),
    },
    teamAvatar: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarCaption: {
        fontSize: '60%',
        fontStyle: 'italic',
    },
    title: {
        fontSize: '110%',
    },
    subtitle: {
        paddingTop: -3,
        marginTop: -3,
        fontSize: '85%',
        fontStyle: 'italic',
    },
    smallAvatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
    },
}));

const Event = (props) => {
    const { state, dispatch } = useContext(appStore);
    const { leagueID, gameID, eventID } = props.match.params;
    const { t } = useTranslation();
    const classes = useStyles(state);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [data, setData] = useState(null);

    useEffect(() => {
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:event' } });
        return () => {
        }
    }, []);

    useEffect(() => {
        props.setLeagueID(leagueID);
        if (!eventID) return;
        loadData();
    }, [gameID, eventID]);

    const loadData = async () => {
        try {
            setIsLoading(true);
            var result = await httpClient('get', 'Calendar/GetEventAndVideos', { id: eventID });
            setData(result);
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader isLoading={isLoading}><div></div></Loader>
    }

    return (
        <>
            <List disablePadding>
                <ListItem dense disableGutters divider>
                    <ListItemAvatar>
                        <div className={classes.teamAvatar}>
                            <Avatar className={classes.smallAvatar} component={Link} to={`/home/calendar/${leagueID}/summary/${gameID}`}>
                                <BackIcon />
                            </Avatar>
                        </div>
                    </ListItemAvatar>
                    <ListItemText
                        primary={data ? data.gameTitle : ''}
                        secondary={
                            <Moment locale={i18next.language} format="ddd Do MMMM YYYY - HH:mm" date={data ? data.gameDateTime : ''} />
                        } />
                    <>
                        <ButtonGroup size='small'>
                            <IconButton
                                disabled={!data || !data.previousEventID}
                                component={Link} to={`/home/calendar/${leagueID}/summary/${gameID}/event/${(data ? data.previousEventID : eventID)}`}
                            >
                                <NavigateBeforeIcon />
                            </IconButton>

                            <IconButton
                                disabled={!data || !data.nextEventID}
                                component={Link} to={`/home/calendar/${leagueID}/summary/${gameID}/event/${(data ? data.nextEventID : eventID)}`}
                            >
                                <NavigateNextIcon />
                            </IconButton>
                        </ButtonGroup>
                    </>
                </ListItem>
            </List>
            
            <ScrollBody>
                <Grid
                    container
                    alignItems="flex-start"
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                    >
                        <Tabs
                            value={selectedTabIndex}
                            onChange={(e, v) => setSelectedTabIndex(v)}
                            variant={"scrollable"}
                            className={classes.tabs}
                        >
                            {
                                (data && data.cameras ? data.cameras : []).map((x, i) =>
                                    <Tab
                                        key={i}
                                        label={'CAM ' + (i + 1)}
                                    />
                                )
                            }
                        </Tabs >

                        <ReactPlayer
                            style={{ margin: 0, padding: 0 }}
                            width={state.isMobile ? state.width * .9 : ''}
                            height={state.isMobile ? '100%' : ''}
                            url={data && data.cameras && data.cameras[selectedTabIndex] ? data.cameras[selectedTabIndex].videoUrl : ''}
                            controls
                            playing={false}
                        />

                        <List>
                            <ListItem
                                divider
                                dense
                                disableGutters
                            >
                                <ListItemAvatar>
                                        <Avatar
                                            src={data.team ? data.team.logoUrl : ''}
                                            className={classes.smallAvatar}
                                        />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={`${data ? data.title : ''}${data.team ? ' (' + data.team.name + ')' : ''}`}
                                    secondary={
                                        data ? `${(data.subtile ? data.subtile : '')}${(data.details ? (data.subtile ? ' | ' : '') + t('actions:assist') + data.details : '')}` : ''
                                    }
                                />

                                <>
                                    <Chip
                                        size={"small"}
                                        avatar={
                                            <Avatar>
                                                {data.period}
                                            </Avatar>
                                        }
                                        label={convertMillisToTime(data.time)}
                                    />
                                </>
                            </ListItem>
                        </List>

                    </Grid>

                    <Grid item xs={12} sm={6} >
                        <EventComments eventID={eventID} leagueID={leagueID} />
                    </Grid>

                </Grid>
            </ScrollBody>
        </>
    )
}
export default Event

import i18next from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const labels = {
    en: {
        common: {
            about:'Welcome',
            greetings: 'Hello',
            login: 'Login',
            profile: 'Profile',
            logout: 'Logout',
            loggedOut: 'You are now logged out',
            admin: 'Admin',
            security: 'Security',
            roles: 'Roles',
            users: 'Users',
            settings: 'Settings',
            changeLanguage: 'Français',
            loading: 'Loading...',
            games: 'Games',
            teams: 'Teams',
            seasons: 'Seasons',
            leagues : 'Leagues',
            calendar: 'Calendar',
            summary: 'Game summary',
            event: 'Sequence',
            statistics: 'Statistics',
            players: 'Players',
            views: 'Views',
            comments: 'Comments',
            comment: 'Comment',
            all: 'All',
            assist: 'Assists : ',
            hasMore: 'More...',
            name: 'Name',
            basics: 'Basics',
            videoStatistics: 'Video Statistics',
            gameNumber: '# of games',
            ipNumber: '# unique visitor',
            videoNumber: '# videos',
            start: 'Start',
            end: 'End',
            isActive: 'Active',
            isPublic:'Publique',
            contactUs :'Contact us',            
            contactUsDetail :'Automatically sync your videos and stats!',
            reportError: 'You think something\'s broken, you would be kind to let us know',
            sorryNotFound: 'Sorry, your page was not found.',
            sorryToHear: 'We hope to see you around here soon!',
            reportComments: 'You want to give us feedback? Click here',
            getBackHome: 'Let us take you back home (click here)',
            privacyPolicy: 'Privacy Policy',
            mediaElements :'Media element',
            getStarted :'Get started!',
            goToLeagues :'Choose league...',
        },
        actions: {
            access: 'Access',
            save: 'Save',
            edit: 'Edit',
            add: 'Add',
            cancel: 'Cancel',
            delete: 'Delete',
            success: 'Ok',
            search: 'Search',
            goBack: 'Back',
            remove: 'Remove',
            read: 'Read',
            write: 'Write',
            newLeague: 'New league',
            editLeague: 'Edit league',
            manageAccess:'Manage accesses',
        },
        statistic:{
            gamesPlayed : 'GP',
            points : 'PTS',
            win : 'WIN',
            otLoss : 'OTL',
            tie : 'TIE',
            loss: 'LOS',
            fairPlay: 'FP',
            goalDiff : 'G DIF',
        },
        errors: {
            usersInRole: 'Cannot delete a role that has one or more users',
            InvalidRoleName: 'A role cannot be null',
        },
        media: {
            aboutVideo: 'https://www.youtube.com/watch?v=oeI8eqZ1MQY',
        }
    },
    fr: {
        common: {
            about:'Accueil',
            greetings: 'Bonjour',
            login: 'Ouvrir session',
            profile: 'Profil',
            logout: 'Fermer',
            loggedOut: 'Déconnexion réussie',
            admin: 'Admin',
            summary: 'Sommaire',
            event: 'Séquence',
            security: 'Sécurité',
            roles: 'Rôles',
            users: 'Utilisateurs',
            settings: 'Paramètres',
            changeLanguage: 'English',           
            loading: 'Chargement...',
            games: 'Parties',
            teams: 'Équipes',
            seasons: 'Saisons',
            leagues: 'Ligues',
            calendar: 'Calendrier',
            statistics: 'Statistiques',
            players: 'Joueurs',
            views: 'vues',
            comments: 'commentaires',
            comment: 'commentaire',
            all: 'Toutes',
            assist: 'Aides : ',
            hasMore: 'Plus...',
            name: 'nom',
            basics: 'Général',
            isActive: 'Actif',
            videoStatistics: 'Statistiques de vidéos',
            gameNumber: 'Nb de matchs',
            ipNumber: 'Nb de visiteurs uniques',
            videoNumber: 'Nb de vidéos',
            start: 'Début',
            end: 'Fin',
            isPublic:'Public',
            contactUs :'Contactez-nous',
            contactUsDetail: 'Synchroniser automatiquement vos reprises vidéos et vos statistiques!',
            reportError: 'Vous pensez que quelque chose est brisé? Vous serirez aimable de nous laisser savoir.',
            sorryNotFound: 'Désolé, nous n\'avons pas trouvé votre page.',
            getBackHome: 'Laissez-nous vous ramener à l\'acceuil (cliquez ici)',
            sorryToHear: 'Nous espérons vous revoir bientôt! ',
            reportComments: 'Vous souhaitez nous faire part de vos commentaires? Cliquez ici.',
            privacyPolicy: 'Politique de Confidentialité',
            mediaElements :'Élément multimédia',
            getStarted :'On commence!',
            goToLeagues :'Choisir ligue...',
        },
        actions: {
            access: 'Accès',
            save: 'Sauvegarder',
            edit: 'Éditer',
            add: 'Ajouter',
            cancel: 'Annuler',
            delete: 'Supprimer',
            success: 'Ok',
            search: 'Recherche',
            goBack: 'Retour',
            remove: 'Retirer',
            read: 'Lecture',
            write: 'Écriture',
            newLeague: 'Nouvelle league',
            editLeague: 'Éditer ligue',
            manageAccess:'Gérer les accès',
        },
        statistic:{
            gamesPlayed : 'PJ',
            points : 'PTS',
            win : 'VIC',
            otLoss : 'DP',
            tie : 'NULL',
            loss : 'DEF',
            fairPlay: 'PFJ',
            goalDiff : 'B DIF',
            goalsAgainst : 'BC',
        },
        errors: {
            usersInRole: 'Un rôle ayant un ou plusieurs usagers ne peut être supprimé',
            InvalidRoleName: 'Le rôle ne peut être null',
        },
        media: {
            aboutVideo: 'https://www.youtube.com/watch?v=sgPJSg5qnIo',
        }
    }
};

i18next
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) 
    .init({
        
        resources: labels,
        fallbackLng: ["en", "fr"],
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        preload: true,
        ns: ["common", "login"],
        defaultNS: "common",
        fallbackNS: "common",
        whitelist: ["en", "fr"],

        keySeparator: true, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            useSuspense: false,
        },
        detection: {
            order: ['querystring', 'cookie', 'navigator'],
            caches: ['cookie'],
            supportedLngs: ['fr', 'en'],
            cookieMinutes: 525600,   // 1 year
            lookupQuerystring: 'lang',
            lookupFromPathIndex: 0
        }
    });

export default i18next;
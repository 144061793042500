import React, { useState, useContext, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import i18next from "i18next";

import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';

import useList from '../hooks/useList';
import SearchBox from '../_shared/SearchBox';
import ScrollBody from '../_shared/ScrollBody';
import httpClient from '../_shared/HttpClient';
import { translatedString } from '../_shared/StringFormat';

//store
import { appStore } from '../../appStore.js';


const useStyles = makeStyles(theme => ({
    avatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
    icon: {
        color: theme.palette.secondary.contrastText,
    },
}));

const MediaElementList = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [searchStr, setSearchStr] = useState('');
    const { state, dispatch } = useContext(appStore);

    const [isLoading, setIsLoading, rows, setRows, hasMore, setHasMore, upsertRow, removeRow] = useList('title');

    const take = 10;
    const skip = rows ? rows.length : 0;

    useEffect(() => {
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:mediaElements' } });
        return () => {
        }
    }, []);

    const loadData = async (append) => {
        if (isLoading || (!hasMore && append)) return;
        setIsLoading(true);

        if (!append) {
            setRows([]);
        }
        try {
            var result = await httpClient('get', 'MediaElement/list', { context: null, mediaType: null, skip: skip, take: take, searchStr: searchStr });

            if (append) {
                setRows([...rows, ...result])
            }
            else {
                setRows(result);
            }
            setHasMore(result.lenght === take);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
        <div>

            <Grid
                container
                spacing={2}
                direction="row-reverse"
            >
                <Grid item xs={12} sm={4}>

                    <SearchBox
                        searchStr={searchStr}
                        setSearchStr={setSearchStr}
                        callback={loadData}
                    />

                </Grid>
            </Grid>

            <ScrollBody callbackPrevious={() => loadData(true)} isLoading={isLoading} hasMorePrevious={hasMore}>

                <List>

                    <ListItem
                        button
                        component={Link}
                        to={`mediaElements/0`}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.avatar}>
                                <AddIcon fontSize="small" className={classes.icon} />
                            </Avatar>
                        </ListItemAvatar>
                    </ListItem>

                    {
                        (rows ? rows : []).map((x, i) =>

                            <ListItem
                                key={i}
                                button
                                component={Link}
                                to={`mediaElements/${x.mediaElementID}`}
                            >
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <EditIcon fontSize="small" className={classes.icon} />
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                    primary={JSON.parse(x.title)[i18next.languages[0]]}
                                    secondary={JSON.parse(x.detail)[i18next.languages[0]]}
                                />
                            </ListItem>
                        )
                    }
                </List>
            </ScrollBody>

        </div>
    )
}
export default MediaElementList

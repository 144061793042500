import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from 'throttle-debounce';
import { makeStyles } from '@material-ui/core/styles';

import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
}));

const SearchBox = (props) => {
    const classes = useStyles();
    const { searchStr, setSearchStr, callback } = props;
    const { t } = useTranslation();

    const deboucedCallback = useCallback(debounce(2000, (searchStr) => callback(searchStr, false)), []);

    const handleChange = (event) => {
        setSearchStr(event.target.value);
    }

    const handleCancel = () => {
        setSearchStr('');
    }

    useEffect(() => {
        //debouced if user typing...
        if (searchStr) {
            deboucedCallback(searchStr, false);
        }
        else {
            callback(searchStr, false);
        }
    }, [searchStr]);

    return (
        <Input
            placeholder={t('actions:search')}
            value={searchStr}
            onChange={handleChange}
            fullWidth
            endAdornment={
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleCancel}
                        edge="end"
                        disabled={!searchStr}
                    >
                        <ClearIcon />
                    </IconButton>
                </InputAdornment>
            }
        />
    )
}
export default SearchBox

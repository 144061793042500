import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, BrowserRouter as Router, Link } from 'react-router-dom';

//ui
import CalendarIcon from '@material-ui/icons/CalendarToday';
import StatisticIcon from '@material-ui/icons/FormatListNumbered';
import LeagueIcon from '@material-ui/icons/SportsHockey';
import InfoIcon from '@material-ui/icons/Info';

//custom
import Calendar from './home/Calendar';
import Summary from './home/Summary';
import Info from './home/Info';
import Event from './home/Event';
import Statistics from './home/Statistics';
import LeagueList from './home/LeagueList';
import League from './home/League';
import Privacy from './home/Privacy';
import DrawerNavigation from './_shared/DrawerNavigation';
import httpClient from './_shared/HttpClient';

import { appStore } from '../appStore.js';

const Home = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const [leagueID, setLeagueID] = useState(null);

    useEffect(() => {
        const init = async () => {
            dispatch({ type: 'updateNavigatingAdmin', payload : false });
            if (!leagueID || (state.league !== null && leagueID === state.league.LeagueID)) return;
            try {
                var result = await httpClient('get', 'league/GetPresenter', { id: leagueID });
                localStorage.setItem('leagueID', leagueID)
                if (result) {
                    dispatch({ type: 'updateLeague', payload: { league: result } });
                }
            }
            catch (err) {
                console.log(err);
            }
        };
        init();
        return () => {
        }
    }, [leagueID]);

    const tabs = localStorage.getItem('leagueID') ? [
        { label: t('common:calendar'), path: `/home/calendar/${leagueID}`, roles: null, icon: <CalendarIcon /> },
        { label: t('common:statistics'), path: `/home/statistics/${leagueID}`, roles: null, icon: <StatisticIcon /> },
        { label: t('common:leagues'), path: '/home/leagues', roles: null, icon: <LeagueIcon /> },
        { label: t('common:about'), path: '/home/info', roles: null, icon: <InfoIcon /> },
    ] :
    
    [
        { label: t('common:leagues'), path: '/home/leagues', roles: null, icon: <LeagueIcon /> },
        { label: t('common:about'), path: '/home/info', roles: null, icon: <InfoIcon /> },
    ];

    return (
        <>
            <Route path='/home' render={(props) =>
                <DrawerNavigation {...props} tabs={tabs} />
            } />

            <Route exact path='/home' render={(props) =>
                <Redirect to={localStorage.getItem('leagueID') ? `/home/calendar/${localStorage.getItem('leagueID')}` : '/home/info'} />
            } />

            <Route exact path='/home/leagues' render={(props) =>
                <LeagueList {...props} />
            } />

            <Route exact path='/home/leagues/:id' render={(props) =>
                <League {...props} />
            } />

            <Route exact path='/home/calendar/:leagueID' render={(props) =>
                <Calendar {...props} setLeagueID={setLeagueID} />
            } />

            <Route exact path='/home/calendar/:leagueID/summary/:gameID' render={(props) =>
                <Summary {...props} setLeagueID={setLeagueID} />
            } />

            <Route exact path='/home/calendar/:leagueID/summary/:gameID/event/:eventID' render={(props) =>
                <Event {...props} setLeagueID={setLeagueID} />
            } />

            <Route exact path='/home/statistics/:leagueID' render={(props) =>
                <Redirect to={`/home/statistics/${props.match.params.leagueID}/team`} />
            } />

            <Route exact path='/home/statistics/:leagueID/:ranking' render={(props) =>
                <Statistics {...props} setLeagueID={setLeagueID} />
            } />

            <Route exact path='/home/info/:page?' render={(props) =>
                <Info {...props} />
            } />

            <Route exact path='/home/privacy' render={(props) =>
                <Privacy {...props} />
            } />

        </>
    )
}
export default Home

import axios from 'axios';
import authService from '../api-authorization/AuthorizeService';

const httpClient = async (method, url, data) => {
    const token = await authService.getAccessToken();
    let result;

    switch (method) {
        case 'get':
            result = await axios({
                method: method,
                url: url,
                params: data,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            })
            break;

        case 'delete':
            result = await axios({
                method: method,
                url: url,
                params: data,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            })
            break;

        case 'formFile':
            result = await axios({
                method: 'post',
                url: url,
                data: data,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'content-type': 'multipart/form-data' },
            })
            break;

        case 'deletePost':
            result = await axios({
                method: 'delete',
                url: url,
                data: data,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            })
            break;

        default:
            result = await axios({
                method: method,
                url: url,
                data: data,
                headers: !token ? {} : { 'Authorization': `Bearer ${token}` },
            })
            break;
    }
    return result.data;
}
export default httpClient;
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';

import httpClient from '../_shared/HttpClient';
import useList from '../hooks/useList';
import ScrollBody from '../_shared/ScrollBody';

const useStyles = makeStyles(theme => ({
    tabs: {
        marginBottom: theme.spacing(1),
    },
    table: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
    th: {
        fontWeight: '550',
        fontSize: '75%',
        marginBottom: theme.spacing(4),

    },
    smallAvatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
    },
}));

const TeamRanking = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { leagueID } = props.match.params;
    const [isLoading, setIsLoading, rows, setRows, hasMore, setHasMore, removeRow] = useList('SeasonDescription');


    const take = 1;

    useEffect(() => {
        props.setLeagueID(leagueID);
        loadData();
    }, [leagueID]);

    const loadData = async (append) => {
        console.log('asd')
        if (isLoading || (!hasMore && append)) return;

        setIsLoading(true);


        if (!append) {
            setRows([]);

        }

        try {
            var result = await httpClient('get', 'ranking/GetTeamRanking', { skip: append ? rows ? rows.length : 0 : 0, take: take, leagueID: leagueID });

            if (append) {
                setRows([...rows, ...result])
 
            }
            else {
                setRows(result);

            }
            setHasMore(result.length === take);
 
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setIsLoading(false);

        }
    };

    

    return (
        <ScrollBody callbackPrevious={() => loadData(true)} isLoadingPrevious={isLoading} hasMorePrevious={hasMore}>
            <>
                {
                                            
                            (rows ? rows : []).map((x, i) =>

                                <TableContainer key={i}>

                                    <Chip label={x.seasonDescription} variant="default" color={"primary"} />
                                    {(x.divisionDetails ? x.divisionDetails : []).map((d, j) =>



                                        <TableContainer key={j}>

                                            {x.divisionDetails.length>1 ?  <Chip label={d.name} variant="default" color={"secondary"} /> : <br/>}

                                                <Table size="small" className={classes.table}>
                                                    <TableHead>

                                                        <TableRow size='medium'>

                                                            <TableCell
                                                                align="left"
                                                                className={classes.th}
                                                                padding={'none'}
                                                            >
                                                                #
                                                            </TableCell>

                                                            <TableCell
                                                                align="left"
                                                                className={classes.th}
                                                            >
                                                                {t('common:teams')}
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:gamesPlayed')}
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:points')}
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:win')}
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:otLoss')}
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:tie')}
                                                            </TableCell>

                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:loss')}
                                                            </TableCell>
                                                            {x.fairPlayOn &&
                                                                <TableCell
                                                                    align="right"
                                                                    className={classes.th}
                                                                    style={{ textAlign: 'center' }}
                                                                >
                                                                    {t('statistic:fairPlay')}
                                                                </TableCell>}
                                                            <TableCell
                                                                align="right"
                                                                className={classes.th}
                                                                style={{ textAlign: 'center' }}
                                                            >
                                                                {t('statistic:goalDiff')}
                                                            </TableCell>

                                                        </TableRow>
                                                    </TableHead>

                                                    <TableBody>

                                                        {(d.teamRankingDetails ? d.teamRankingDetails : []).map((row, index) => (
                                                            <TableRow key={index}>

                                                                <TableCell align="left" component="th" scope="row" className={classes.th} padding={'none'}>
                                                                    {row.rank}
                                                                </TableCell>

                                                                <TableCell align="left" padding='none'>


                                                                    <ListItem>
                                                                        <ListItemAvatar>
                                                                            <Avatar
                                                                                className={classes.smallAvatar}
                                                                                src={row.team.logoUrl}
                                                                            />
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            className={classes.tr}
                                                                            primary={
                                                                                <Typography noWrap variant='caption'>
                                                                                    {row.team.name}
                                                                                </Typography>
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                </TableCell>

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.gamesPlayed}
                                                                </TableCell>

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.points}
                                                                </TableCell>

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.win}
                                                                </TableCell>

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.otLoss}
                                                                </TableCell>

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.tie}
                                                                </TableCell>

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.loss}
                                                                </TableCell>
                                                                {x.fairPlayOn &&
                                                                    <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                        {row.fairPlay}
                                                                    </TableCell>}

                                                                <TableCell align="right" style={{ textAlign: 'center' }}>
                                                                    {row.goalDiff}
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}

                            </TableContainer>


                    )}

                
            </>
        </ScrollBody>
    )
}
export default TeamRanking

import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from "i18next";

import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player'
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import PrivacyIcon from '@material-ui/icons/Https';
import LeagueIcon from '@material-ui/icons/SportsHockey';
import VideoIcon from '@material-ui/icons/Movie';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import httpClient from '../_shared/HttpClient';
import Loader from '../_shared/Loader';
import {translatedString} from '../_shared/StringFormat';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    primaryColor: {
        backgroundColor: theme.palette.primary.main,
    },
    videoPlayer:{
        borderRadius: theme.spacing(1),
        overflow: 'hidden',
    }
}));

const InfoAbout = (props) => {
    const { state, dispatch } = useContext(appStore);
    const { t } = useTranslation();
    const classes = useStyles(state);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(null);
    const [data, setData] = useState(null);

    const take = 10;
    const skip = data ? data.length : 0;

    useEffect(() => {
        loadData();
        dispatch({ type: 'updateNavigation', payload: { navTitle: 'common:about' } });
        return () => {
        }
    }, []);

    const loadData = async () => {
        try {
            setIsLoading(true);
            var result = await httpClient('get', 'MediaElement/List', { context: 'infoAbout', mediaType: 'video', skip, take });
            if (result) {
                setData(result);
                setSelected(result[0])
            }
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return <Loader isLoading={isLoading}><div></div></Loader>
    }

    return (
        <>
            <Typography
                variant={'h6'}
                color='primary'
            >
                {selected ? JSON.parse(selected.title)[i18next.languages[0]] : ''}
            </Typography>

            <Typography
                variant={'subtitle1'}
                gutterBottom={true}
            >
                {selected ? JSON.parse(selected.detail)[i18next.languages[0]] : ''}
            </Typography>

            <Grid container spacing={2} alignItems={'flex-start'}>

                <Grid item xs={12} sm={8}>

                        <ReactPlayer
                            playing={true}
                            width='100%'
                            height={'100%'}
                            url={selected ? selected.url : ''}
                            controls
                            className={classes.videoPlayer}
                        />
                </Grid>

                <Grid item xs={12} sm={4}>

                    {
                        (data ? data.filter(e => e.mediaElementID !== (selected ? selected.mediaElementID : null)) : []).map((x, i) =>

                            <ListItem
                                key={i}
                                disableGutters
                                button
                                onClick={() => setSelected(x)}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <VideoIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={JSON.parse(x.title)[i18next.languages[0]]}
                                    secondary={JSON.parse(x.detail)[i18next.languages[0]]}
                                />
                            </ListItem>
                        )
                    }

                    <ListItem
                        disableGutters
                        color={'primary'}
                        button
                        component={Link}
                        to={`/home/leagues`}
                    >
                        <ListItemAvatar>
                            <Avatar className={classes.primaryColor}>
                                <LeagueIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('common:getStarted')}
                            secondary={t('common:goToLeagues')}
                        />
                    </ListItem>

                    <ListItem
                        disableGutters
                        button
                        component="a"
                        href="mailto:info@syncstats.com"
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <EmailIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('common:contactUs')}
                            secondary={'info@syncstats.com'}
                        />

                    </ListItem>

                    <ListItem
                        disableGutters
                        button
                        component={Link}
                        to={`/home/privacy`}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <PrivacyIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('common:privacyPolicy')}
                        />

                    </ListItem>
                </Grid>
            </Grid>

        </>
    )
}
export default InfoAbout

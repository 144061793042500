import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReactPlayer from 'react-player'


const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        border: 1,
    },
}));

const VideoPicker = (props) => {
    const { handleChange, url } = props;
    const { t } = useTranslation();
    const classes = useStyles();


    const [anchorEl, setAnchorEl] = useState(null);
    const [inputSelection, setInputSelection] = useState('');
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);

    const inputFile = useRef(null);

    const handleFileChange = (event) => {
        const { target } = event;
        const { files } = target;

        if (files && files[0]) {
            var reader = new FileReader();

            reader.onloadstart = () => setLoading(true);

            reader.onload = event => {
                setPreview(event.target.result);
                setLoading(false);
            };

            handleChange(files[0]);
            reader.readAsDataURL(files[0]);
            setInputSelection('');
        }
    }

    const handleClick = (event) => {
        if (preview || url) {
            setAnchorEl(event.currentTarget)
        }
        else {
            inputFile.current.click()
        }
    }

    const handleMenuItemClick = (action) => {
        action();
        handleMenuClose();
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleDeleteMenuItemClick = () => {
        handleChange(null);
        setPreview(null);
    }

    return (
        <>
            <input
                type='file'
                ref={inputFile}
                style={{ display: 'none' }}
                accept="video/*"
                capture="camera"
                onChange={handleFileChange}
                value={inputSelection}
                multiple={false}
            />

            <ReactPlayer
                onClick={handleClick}
                url={preview ? preview : url ? url : ''}
                controls
            />

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {/* <MenuItem onClick={handleMenuClose}>{t('actions:cancel')}</MenuItem> */}
                <MenuItem onClick={() => handleMenuItemClick(() => inputFile.current.click())}>{t('actions:edit')}</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick(handleDeleteMenuItemClick)}>{t('actions:delete')}</MenuItem>
            </Menu>
        </>
    );
}
export default VideoPicker


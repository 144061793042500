import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, BrowserRouter as Router, Link } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Toolbar from '@material-ui/core/Toolbar';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import BackIcon from '@material-ui/icons/ArrowBack';

import AuthorizeComponent from './AuthorizeComponent';

//store
import { appStore } from '../../appStore.js';


const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    button: {
        marginBottom : theme.spacing(1),
        marginTop : theme.spacing(1),        
    },
    selector: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
    },
    logo: {
        margin: theme.spacing(2),
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    grow: {
        flexGrow: 1,
    },
}));

const Admin = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const classes = useStyles();
    const { tabs } = props;

    const [drawerState, setDrawerState] = useState({
        open: false,
    });

    const authorizedTabs = tabs.filter(x => AuthorizeComponent(x.roles));

    const toggleDrawer = (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerState({ ...drawerState, open: !drawerState.open });
    };


    const handleGoBack = () => {
        setDrawerState({ ...drawerState, open: false });
        if (props.back) {
            props.history.push(props.back);
        }
        else {
            props.history.goBack();
        }
    }

    return (
        <>
            <Button
            className={classes.button}
            size='small'
                onClick={toggleDrawer} startIcon={<MenuIcon className={classes.selector} />}>
                <Typography noWrap variant="subtitle1">
                {`${t(state.navTitle)}${(state.navDetail ? ` - ${t(state.navDetail)}` : '')}`}
                </Typography>
            </Button>

            <SwipeableDrawer
                open={drawerState.open}
                onOpen={toggleDrawer}
                onClose={toggleDrawer}
            >


                <List>

                    <Avatar
                        className={classes.logo}
                        src={state.league && state.league.logoUrl ? state.league.logoUrl : "/images/thumbnail_logo.png"}
                    />

                    <ListItem
                        onClick={handleGoBack}
                        button
                    >
                        <ListItemIcon>
                            <BackIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('actions:goBack')} />
                    </ListItem>

                    <Divider />

                    {authorizedTabs.map((x, i) => (
                        <div key={i}>

                            {
                                x.hidden ? '' : <ListItem
                                    button
                                    to={`${x.pathParam ? x.pathParam : x.path}`}
                                    component={Link}
                                    onClick={toggleDrawer}
                                >
                                    <ListItemIcon>{x.icon}</ListItemIcon>
                                    <ListItemText primary={x.label} />
                                </ListItem>
                            }

                        </div>
                    ))}

                    <Divider />

                    <ListItem
                        button
                        onClick={toggleDrawer}>
                        <ListItemIcon>
                            <CancelIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('actions:cancel')} />
                    </ListItem>
                </List>

            </SwipeableDrawer>

        </>
    );
}
export default Admin

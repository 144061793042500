import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Redirect, BrowserRouter as Router, Link } from 'react-router-dom';

//ui
import UserIcon from '@material-ui/icons/AccountCircle';
import MediaElementIcon from '@material-ui/icons/PermMedia';

//custom
import UserList from './admin/UserList';
import User from './admin/User';
import MediaElementList from './admin/MediaElementList';
import MediaElementForm from './admin/MediaElementForm';

import DrawerNavigation from './_shared/DrawerNavigation';

//store
import { appStore } from '../appStore.js';

const Admin = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);

    useEffect(() => {
        dispatch({ type: 'updateNavigatingAdmin', payload : true });
        return () => {
        }
    }, []);

    const tabs = [
        { label: t('common:users'), path: '/admin/users', roles: ['Admin'], icon: <UserIcon /> },
        { label: t('common:mediaElements'), path: '/admin/mediaElements', roles: ['Admin'], icon: <MediaElementIcon /> },
    ];

    return (
        <>
            <Route path='/admin' render={(props) =>
                <DrawerNavigation {...props} tabs={tabs} />
            } />

            <Route exact path='/admin' render={(props) =>
                <Redirect to="/admin/users" />
            } />

            <Route exact path='/admin/users' render={(props) =>
                <UserList {...props} />
            } />

            <Route exact path='/admin/users/:id' render={(props) =>
                <User {...props} />
            } />

            <Route exact path='/admin/mediaElements' render={(props) =>
                <MediaElementList {...props} />
            } />

            <Route exact path='/admin/mediaElements/:id' render={(props) =>
                <MediaElementForm {...props} />
            } />



        </>
    )
}
export default Admin

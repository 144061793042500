import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BackIcon from '@material-ui/icons/NavigateBefore';
import DeleteIcon from '@material-ui/icons/Delete';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';

import FormValidation from '../_shared/FormValidation';
import LeagueForm from './LeagueForm';
import VideoStatsReport from './VideoStatsReport';
import ScrollBody from '../_shared/ScrollBody';
import httpClient from '../_shared/HttpClient';

const useStyles = makeStyles((theme) => ({
    fab: {
        marginBottom: theme.spacing(.5),
    },
    title: {
        fontSize: '75%',
    },
    heading: {
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    listItemText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
}));

const League = (props) => {
    const { t } = useTranslation();
    const { id } = props.match.params;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(id === '0' ? 'basics' : '');
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleDelete = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await httpClient('delete', 'League/remove', { id: id });
            if (result === true) {
                props.history.goBack();
            }
            else {
                if (result.errors) {
                    setErrors(result.errors);
                    setIsLoading(false);
                }
            }
        }
        catch (e) {
            setErrors([{ message: e }]);
            setIsLoading(false);
        }
    }

    return (
        <>

            <List>
                <ListItem
                    divider
                >

                    <ListItemAvatar onClick={() => props.history.push('/home/leagues')}>
                        <Avatar
                        >
                            <BackIcon />
                        </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                        classes={{ root: classes.listItemText }}
                        primary={
                            <Typography noWrap>
                                {title}
                          </Typography>
                        }
                    />

                    <ListItemSecondaryAction>
                        <IconButton
                            disabled={id === '0'}
                            onClick={handleDelete}
                            className={classes.deleteButton}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </ListItemSecondaryAction>

                </ListItem>
            </List>

            <FormValidation errors={errors} setErrors={setErrors} />

            <ScrollBody>

                <Accordion expanded={expanded === 'basics'} onChange={handleChange('basics')}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>
                            {t('common:basics')}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <LeagueForm
                            id={id}
                            setTitle={setTitle}
                            history={props.history}
                        />
                    </AccordionDetails>

                </Accordion>
                <Accordion expanded={expanded === 'stats'} onChange={handleChange('stats')}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>
                            {t('common:videoStatistics')}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <List>
                            <ListItem                             
                            >
                                <VideoStatsReport
                                    id={id}
                                    month={0}
                                    setTitle={setTitle}
                                    history={props.history}
                                />
                            </ListItem>
                            <ListItem
                            >
                                <VideoStatsReport
                                    id={id}
                                    month={1}
                                    setTitle={setTitle}
                                    history={props.history}
                                />
                            </ListItem>
                            <ListItem
                            >
                                <VideoStatsReport
                                    id={id}
                                    month={2}
                                    setTitle={setTitle}
                                    history={props.history}
                                />
                            </ListItem>
                            <ListItem
                            >
                                <VideoStatsReport
                                    id={id}
                                    month={3}
                                    setTitle={setTitle}
                                    history={props.history}
                                />
                            </ListItem>
                            <ListItem
                            >
                                <VideoStatsReport
                                    id={id}
                                    month={4}
                                    setTitle={setTitle}
                                    history={props.history}
                                />
                            </ListItem>
                            <ListItem
                            >
                                <VideoStatsReport
                                    id={id}
                                    month={5}
                                    setTitle={setTitle}
                                    history={props.history}
                                />
                            </ListItem>
                            </List>

                       
                        
                    </AccordionDetails>

                </Accordion>

            </ScrollBody>
        </>
    )
}
export default League

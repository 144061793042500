import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';

//Providers
import { StateProvider } from './appStore.js';

ReactDOM.render(
    <StateProvider>
        <App />
    </StateProvider>,
    document.getElementById('root')
)


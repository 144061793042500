import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
// import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'lodash.isequal';

import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import IconButton from '@material-ui/core/IconButton';
import OkIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import FormValidation from '../_shared/FormValidation';
import Loader from '../_shared/Loader';
import objectToFormData from '../_shared/objectToFormData';
import useForm from '../hooks/useForm';
import httpClient from '../_shared/HttpClient';
import ImagePicker from '../_shared/ImagePicker';
import VideoPicker from '../_shared/VideoPicker';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        // backgroundColor: fade(theme.palette.primary.light, .1),
        // borderRadius: theme.spacing(1),
    },
    pictureCell: {
        border: '1px solid'
    },

    okButton: {
        color: theme.palette.info.main,
    },
    cancelButton: {
        color: theme.palette.error.main,
    },
    input: {
        display: "none",
    },
}));

const MediaElementForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { state, dispatch } = useContext(appStore);
    const { id } = props.match.params;
    const [isLoading, setIsLoading, row, setRow, errors, setErrors, updateProp] = useForm();
    const [hasChanges, setHasChanges] = useState(false);
    const [mediaTypes, setMediaTypes] = useState([]);
    const [contexts, setContexts] = useState([]);
    const [prev, setPrev] = useState(null);


    useEffect(() => {
        loadData();
        return () => {
        }
    }, [id]);

    useEffect(() => {
        setHasChanges(!isEqual(prev, row));
    }, [row, prev]);

    const loadData = async () => {
        try {
            setIsLoading(true);
            var result = await httpClient('get', 'MediaElement/Get', { id: id });
            setRow(result.mediaElement);
            setPrev(result.mediaElement);
            setMediaTypes(result.mediaTypes);
            setContexts(result.mediaContexts);


            dispatch({ type: 'updateNavigation', payload: { navTitle: 'actions:edit', navDetail: 'common:mediaElement' } });
        }
        catch (e) {
            setErrors([{ message: e }]);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await httpClient('formFile', 'MediaElement/Upsert', objectToFormData(row));

            if (result.errors) {
                console.log(result.errors);
                setErrors(result.errors);
                setIsLoading(false);
            }
            else {
                props.history.goBack();
            }
        }
        catch (e) {
            setErrors([{ message: e }]);
            setIsLoading(false);
        }
    }

    const handleDelete = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await httpClient('delete', 'MediaElement/remove', { id: id });
            if (result.errors) {
                setErrors(result.errors);
                setIsLoading(false);
            }
            else {
                props.history.goBack();
            }
        }
        catch (e) {
            setErrors([{ message: e }]);
            setIsLoading(false);
        }
    }

    const handlePicturechange = async (value) => {
        updateProp('file', value);
    }

    return (
        <Loader isLoading={isLoading}>
            <Container className={classes.root} maxWidth='sm'>

                <form onSubmit={handleSubmit} >

                    <FormValidation errors={errors} setErrors={setErrors} />

                            <Autocomplete
                                size='small'
                                options={contexts}
                                getOptionLabel={(option) => option}
                                value={row && row.context ? row.context : ''}
                                onSelect={(e) => updateProp('context', e.target.value)}
                                renderInput={(params) => <TextField {...params} label={t('common:contexts')} variant="outlined" />}
                            />

                            <Autocomplete
                                size='small'
                                options={mediaTypes}
                                getOptionLabel={(option) => option}
                                value={row && row.mediaType ? row.mediaType : ''}
                                onSelect={(e) => updateProp('mediaType', e.target.value)}
                                renderInput={(params) => <TextField {...params} label={t('common:mediaTypes')} variant="outlined" />}
                            />

                            {
                                row && row.mediaType === "video" ?
                                    <VideoPicker
                                        url={row.url}
                                        handleChange={handlePicturechange}
                                    />
                                    : <></>

                            }
                            {
                                row && row.mediaType === "picture" ?
                                    <ImagePicker
                                        pictureUrl={row.url}
                                        handleChange={handlePicturechange}
                                    />
                                    : <></>
                            }

                            <TextField
                                fullWidth={true}
                                variant={'outlined'}
                                label={t('common:title')}
                                type="text"
                                value={row && row.title ? row.title : ''}
                                margin='dense'
                                size='small'
                                onChange={(e) => updateProp('title', e.target.value)}
                            />

                            <TextField
                                fullWidth={true}
                                variant={'outlined'}
                                label={t('common:detail')}
                                type="text"
                                value={row && row.detail ? row.detail : ''}
                                margin='dense'
                                onChange={(e) => updateProp('detail', e.target.value)}
                            />

                            <TextField
                                fullWidth={true}
                                variant={'outlined'}
                                label={t('common:sortOrder')}
                                type="number"
                                value={row && row.sortOrder ? row.sortOrder : 0}
                                margin='dense'
                                onChange={(e) => updateProp('sortOrder', e.target.value)}
                            />

                    <Grid
                        container
                    >

                        <Grid item xs={'auto'}>
                            <IconButton
                                disabled={!hasChanges}
                                onClick={handleSubmit}
                                className={classes.okButton}>
                                <OkIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={'auto'}>
                            <IconButton
                                className={classes.cancelButton}
                                onClick={() => props.history.goBack()}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={'auto'}>
                            <IconButton
                                className={classes.cancelButton}
                                onClick={handleDelete}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>

                    </Grid>

                </form>
            </Container>
        </Loader>
    )
}
export default MediaElementForm

import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import i18next from "i18next";

import ScrollBody from '../_shared/ScrollBody'

//store
import { appStore } from '../../appStore.js';

const theme = createMuiTheme({
    typography: {
        fontFamily: 'Oleo Script',
    },
});

const Privacy = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);

    return (
        <ScrollBody>


            <Grid container>
                <Grid
                    item
                    xs={12}
                    sm={6}
                >
                    {i18next.language == "fr" &&
                        <div>
                            <h2>Collecte des renseignements personnels</h2>
                            <p>
                            Nous collectons les renseignements suivants :
                            </p>
                            <ul>
                                <li>
                                Nom
                                </li>
                                <li>
                                Prénom
                                </li>
                                <li>
                                Code postal
                                </li>
                                <li>
                                Adresse électronique
                                </li>
                                <li>
                                Genre / Sexe
                                </li>
                                <li>
                                Âge / Date de naissance
                                </li>
                                <li>
                                Description physique
                                </li>
                            </ul>

                            <p>
                            Les renseignements personnels que nous collectons sont recueillis au travers de formulaires et grâce à
                            l'interactivité établie entre vous et notre site Web.
                            Nous utilisons également, comme indiqué dans la section suivante, des fichiers témoins et/ou journaux
                            pour réunir des informations vous concernant.
                            </p>
                            <hr />
                            <h2>Formulaires et interactivité:</h2>
                            <p>
                            Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :
                            </p>
                            <ul>
                                <li>
                                Formulaire d'inscription au site Web
                                </li>
                            </ul>
                            <p>
                            Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
                            </p>
                            <ul>
                                <li>
                                Gestion du site Web (présentation, organisation)
                                </li>
                            </ul>
                            <p>
                            Vos renseignements sont également collectés par le biais de l'interactivité pouvant s'établir entre vous et notre site Web et ce, de la façon suivante:
                            </p>
                            <ul>
                                <li>
                                Gestion du site Web (présentation, organisation)
                                </li>
                            </ul>
                            <p>
                            Nous utilisons les renseignements ainsi collectés pour les finalités suivantes :
                            <br />
                            </p>
                            <ul>
                                <li>
                                Correspondance
                                </li>
                            </ul>
                            <hr />
                            <h2>Fichiers journaux et témoins </h2>
                            <p>
                            Nous recueillons certaines informations par le biais de fichiers journaux (log file) et de fichiers témoins (cookies). Il s'agit principalement des informations suivantes :
                            </p>
                            <ul>
                                <li>
                                Pages visitées et requêtes
                                </li>
                                <li>
                                Heure et jour de connexion
                                </li>
                            </ul>

                            <br />
                            <p>
                            Le recours à de tels fichiers nous permet :
                            </p>
                            <ul >
                                <li>
                                Amélioration du service et accueil personnalisé
                                </li>
                                <li>
                                Statistique
                                </li>
                            </ul>
                            <hr />
                            <h2>Droit d'opposition et de retrait</h2>
                            <p>
                            Nous nous engageons à vous offrir un droit d'opposition et de retrait quant à vos renseignements personnels.
                            <br />
                            Le droit d'opposition s'entend comme étant la possiblité offerte aux internautes de refuser que leurs renseignements
                            personnels soient utilisées à certaines fins mentionnées lors de la collecte.
                            <br />
                            </p>
                            <p>
                            Le droit de retrait s'entend comme étant la possiblité offerte aux internautes de demander à ce que leurs
                            renseignements personnels ne figurent plus, par exemple, dans une liste de diffusion.
                            <br />
                            </p>
                            <p>
                            Pour pouvoir exercer ces droits, vous pouvez :
                            <br />
                            Code postal : 8552 15e avenue, Montréal H1Z 3N7
                            <br />
                            Courriel : info@syncstats.com
                            <br />
                            Téléphone : 514-887-8429
                            <br />
                            Section du site web : syncstats.com/
                            <br />
                            </p>
                            <hr />
                            <h2>Droit d'accès</h2>
                            <p>
                            Nous nous engageons à reconnaître un droit d'accès et de rectification aux personnes
                            concernées désireuses de consulter, modifier, voire radier les informations les concernant.
                            <br />
                            L'exercice de ce droit se fera :
                            <br />
                            Code postal : 8552 15e avenue, Montréal H1Z 3N7
                            <br />
                            Courriel : info@syncstats.com
                            <br />
                            Téléphone : 514-887-8429
                            <br />
                            Section du site web : syncstats.com/
                            <br />
                            </p>
                            <hr />
                            <h2>Sécurité</h2>
                            <p>

                            Les renseignements personnels que nous collectons sont conservés
                            dans un environnement sécurisé. Les personnes travaillant pour nous sont tenues de respecter la confidentialité de vos informations.
                            <br />
                            Pour assurer la sécurité de vos renseignements personnels, nous avons recours aux mesures suivantes :
                            </p>
                            <ul>
                                <li>
                                Protocole SSL (Secure Sockets Layer)
                                </li>
                                <li>
                                Gestion des accès - personne autorisée
                                </li>
                                <li>
                                Logiciel de surveillance du réseau
                                </li>
                                <li>
                                Sauvegarde informatique
                                </li>
                                <li>
                                Développement de certificat numérique
                                </li>
                                <li>
                                Identifiant / mot de passe
                                </li>
                                <li>
                                Pare-feu (Firewalls)
                                </li>
                            </ul>

                            <p>
                                Nous nous engageons à maintenir un haut degré de confidentialité en intégrant les dernières innovations technologiques permettant d'assurer
                                la confidentialité de vos transactions. Toutefois, comme aucun mécanisme n'offre une sécurité maximale, une part de risque est toujours présente
                                lorsque l'on utilise Internet pour transmettre des renseignements personnels.
                            </p>
                        </div>} {i18next.language != "fr" &&
                            <div>
                                <h2>Personal information collection</h2>
                                <p>
                            We are collecting following informations:
                            </p>
                                <ul>
                                    <li>
                                Last Name
                                </li>
                                    <li>
                                First Name
                                </li>
                                    <li>
                                Postal Code
                                </li>
                                    <li>
                                Email Address
                                </li>
                                    <li>
                                Gender / Sex
                                </li>
                                    <li>
                                Age / Date of birth
                                </li>
                                    <li>
                                Physical Description
                                </li>
                                </ul>

                                <p>
                            Personal information are collected through forms and interactions between you and the web site. As specified in the following section,
                            we collect cookies and/or log files that contains informations related to you.
                            </p>
                                <hr />
                                <h2>Forms and Interactions</h2>
                                <p>
                            Your informations are collected through forms, namely:
                            Vos renseignements personnels sont collectés par le biais de formulaire, à savoir :
                            </p>
                                <ul>
                                    <li>
                                Subscription form for website.
                                </li>
                                </ul>
                                <p>
                            We use collected information for this purpose:
                            
                            </p>
                                <ul>
                                    <li>
                                Website management (presentation,organisation)
                                </li>
                                </ul>
                                <p>
                            You personal informations are also collected through interactions between you and the website in the following way:
                            
                            </p>
                                <ul>
                                    <li>
                                Website management (presentation,organisation)
                                </li>
                                </ul>
                                <p>
                            We also use collected information for this purpose:
                            <br />
                                </p>
                                <ul>
                                    <li>
                                Correspondance
                                </li>
                                </ul>
                                <hr />
                                <h2>Log files and cookies </h2>
                                <p>
                            We collect some informations through log files and cookies. It is mainly following informations:
                            </p>
                                <ul>
                                    <li>
                                Visited pages and requests
                                </li>
                                    <li>
                                Date and time of connection
                                </li>
                                </ul>

                                <br />
                                <p>
                            Using these files allows us to :
                            </p>
                                <ul >
                                    <li>
                                Improve our service and customize experience
                                </li>
                                    <li>
                                Statistics
                                </li>
                                </ul>
                                <hr />
                                <h2>Right of opposition and removal</h2>
                                <p>
                            We are committed to offer you right of oppoistion and removal regarding your personal informations.
                            <br />
                            Right of opposition consists of offering to internet users the possibility to deny usage of their
                            personal information as mentionned when collected.
                            <br />
                                </p>
                                <p>
                            Right of removal consists of offering to internet users the possibility that their personal informations do not shows up, for example, in mailing lists.
                            <br />
                                </p>
                                <p>
                            To use these rights, you can reach us to:
                             
                            <br />
                            Address : 8552 15e avenue, Montréal H1Z 3N7
                            <br />
                            Email : info@syncstats.com
                            <br />
                            Telephone : 514-887-8429
                            <br />
                            Website : syncstats.live/
                            <br />
                                </p>
                                <hr />
                                <h2>Right of access</h2>
                                <p>
                            We are committed to allow right of access and rectification to user wishing to see, edit or delete informations related to them.
                            <br />
                                </p>
                                <p>
                            To use these rights, you can reach us to:
                             
                            <br />
                            Address : 8552 15e avenue, Montréal H1Z 3N7
                            <br />
                            Email : info@syncstats.com
                            <br />
                            Telephone : 514-887-8429
                            <br />
                            Website : syncstats.live/
                            <br />
                                </p>
                                <hr />
                                <h2>Security</h2>
                                <p>
                            Collected personal information are stored in a secured environment. People working for us are mandated to respect privacy of your informations.
                            <br />
                            To ensure security of your personal informations, we are using following measures:</p>
                                <ul>
                                    <li>
                                SSL Protocole (Secure Sockets Layer)
                                </li>
                                    <li>
                                Access Management - Authorized content
                                </li>
                                    <li>
                                Network Monitoring Software
                                </li>
                                    <li>
                                Computer Backups
                                </li>
                                    <li>
                                Digital Certificates
                                </li>
                                    <li>
                                Login / Password
                                </li>
                                    <li>
                                Firewalls
                                </li>
                                </ul>

                                <p>
                            We are commited to maintain high degree of privacy by integrating last technological innovations allowing your transactions privacy insurance.
                            However, as no mechanism offer maximum security, a part of risk is always present when Internet is used to communicate personal informations.
                            </p>
                            </div>}

                    <ListItem
                        disableGutters
                        button
                        component="a"
                        href="https://syncstats.live"

                    >
                        <ListItemAvatar>
                            <Avatar>
                                <HomeIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={t('common:getBackHome')}
                        />
                    </ListItem>

                </Grid>
            </Grid>
        </ScrollBody>

    )
}
export default Privacy

import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'lodash.isequal';
import { SketchPicker } from 'react-color'

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import OkIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';

import ColorPicker from '../_shared/ColorPicker';
import ImagePicker from '../_shared/ImagePicker';
import objectToFormData from '../_shared/objectToFormData';
import FormValidation from '../_shared/FormValidation';
import Loader from '../_shared/Loader';
import useForm from '../hooks/useForm';
import httpClient from '../_shared/HttpClient';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        // backgroundColor: fade(theme.palette.primary.light, .1),
        // borderRadius: theme.spacing(1),
    },
    okButton: {
        color: theme.palette.info.main,
    },
    cancelButton: {
        color: theme.palette.error.main,
    },
    title: {
        flexGrow: 1,
        fontFamily: 'Comic Sans MS, cursive, sans-serif',
        marginLeft: theme.spacing(1),
    },
}));

const LeagueForm = (props) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(appStore);
    const classes = useStyles();
    const { id } = props;
    const [isLoading, setIsLoading, row, setRow, errors, setErrors, updateProp] = useForm();
    const [hasChanges, setHasChanges] = useState(false);
    const [prev, setPrev] = useState(null);

    useEffect(() => {
        loadSport();
        return () => {
        }
    }, [id]);

    useEffect(() => {
        setHasChanges(!isEqual(prev, row));
    }, [row, prev]);

    const loadSport = async () => {
        try {
            setIsLoading(true);
            var row = await httpClient('get', 'league/Get', { id: id });
            setRow(row);
            setPrev(row);

            //set nav title
            props.setTitle(id === '0' ? `${t('actions:add')} ${t('common:league')}` : `${t('actions:edit')} ${row.name}`);
        }
        catch (e) {
            setErrors([{ message: e }]);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await httpClient('formFile', 'league/Upsert', objectToFormData(row));
            if (result.errors) {
                setErrors(result.errors);
                setIsLoading(false);
            }
            else {
                //if league is same as in appNav, update if from presenter
                if (state.league && state.league.leagueID.toString() === id) {
                    dispatch({ type: 'updateLeague', payload: { league: result.toPresenter } });
                }
                props.setTitle(result.name);
                setRow(result);
                setPrev(result);
                setIsLoading(false);

                //update url param if add league
                if (id === '0' && result.leagueID) {
                    props.history.push(`/leagues/edit/${result.leagueID}`);
                }
            }
        }
        catch (e) {
            setErrors([{ message: e }]);
            setIsLoading(false);
        }
    }

    const handlePicturechange = async (value) => {
        updateProp('picture', value, 'logoUrl', '');
    }

    return (
        <Loader isLoading={isLoading}>
            <Container className={classes.root}>

                <form onSubmit={handleSubmit} >

                    <FormValidation errors={errors} setErrors={setErrors} />

                    {
                        !row ? '' :
                            <>
                                <ListItem
                                    style={{
                                        backgroundColor: row.mainColorCode ? row.mainColorCode : '',
                                        borderRadius: '12px',
                                        marginBottom: '12px',
                                        borderStyle: 'solid',
                                        borderWidth: '1px',
                                    }}>
                                    <ListItemAvatar>
                                        <ImagePicker
                                            pictureUrl={row.logoUrl}
                                            handleChange={handlePicturechange}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        classes={{ primary: classes.title }}
                                        style={{ color: row.secondaryColorCode ? row.secondaryColorCode : '' }}
                                        primary={
                                            <Typography noWrap>
                                                {row.name ? row.name : ''}
                                            </Typography>
                                        }
                                    />

                                </ListItem>

                                <Grid container direction="row-reverse">
                                    <Grid item xs='auto'>
                                        <ColorPicker color={row.secondaryColorCode ? row.secondaryColorCode : ''} onChange={updateProp} propName={'secondaryColorCode'} />
                                    </Grid>
                                    <Grid item xs='auto'>
                                        <ColorPicker color={row.mainColorCode ? row.mainColorCode : ''} onChange={updateProp} propName={'mainColorCode'} />
                                    </Grid>
                                </Grid>


                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth={true}
                                            variant={'outlined'}
                                            label={t('sport:code')}
                                            type="text"
                                            value={row.code ? row.code : ''}
                                            onChange={(e) => updateProp('code', e.target.value)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} sm={8}>
                                        <TextField
                                            fullWidth={true}
                                            variant={'outlined'}
                                            label={t('common:name')}
                                            type="text"
                                            value={row.name ? row.name : ''}
                                            onChange={(e) => updateProp('name', e.target.value)}
                                        />
                                    </Grid>

                                </Grid>

                                <Grid container>
                                    <Grid item xs>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={row.isActive}
                                                    onChange={(e) => updateProp('isActive', !row.isActive)}
                                                />
                                            }
                                            label={t('common:isActive')}
                                        />
                                    </Grid>

                                    <Grid item xs={'auto'}>
                                        <IconButton
                                            disabled={!hasChanges}
                                            onClick={handleSubmit}
                                            className={classes.okButton}>
                                            <OkIcon />
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={'auto'}>
                                        <IconButton
                                            className={classes.cancelButton}
                                            onClick={() => setRow(prev)}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </Grid>

                                </Grid>
                            </>
                    }

                </form>

               
            </Container>
        </Loader >
    )
}
export default LeagueForm

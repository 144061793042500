import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import InfoAbout from './InfoAbout';

const useStyles = makeStyles(theme => ({
}));

const Info = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <Route exact path='/home/info' render={(props) =>
                <InfoAbout />
            } />
        </>
    )
}
export default Info

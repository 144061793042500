import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

//UI
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
}));
const FormValidation = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            {
                (props.errors ? props.errors : []).map((e, i) =>

                    <Alert
                        className={classes.root}
                        key={i}
                        elevation={6}
                        variant="filled"
                        severity="error"
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    props.setErrors([]);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {t(e.message)}
                    </Alert>
                )
            }
        </>
    )
}
export default FormValidation

import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Fab from '@material-ui/core/Fab';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import SaveIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';

import httpClient from '../_shared/HttpClient';
import useForm from '../hooks/useForm';
import Loader from '../_shared/Loader';
import { AuthorizeFunction } from '../_shared/AuthorizeFunction';
import objectToFormData from '../_shared/objectToFormData';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    TextField: {
        margin: theme.spacing(0),
    },
    avatarCaption: {
        fontSize: '60%',
        fontStyle: 'italic',
    },
    title: {
        fontSize: '110%',
    },
    subtitle: {
        paddingTop: -3,
        marginTop: -3,
        fontSize: '85%',
        fontStyle: 'italic',
    },
    smallAvatar: {
        width: props => theme.spacing(4),
        height: props => theme.spacing(4),
    },
}));

const EventCommentInput = (props) => {
    const { eventID, addComment } = props;
    const { state, dispatch } = useContext(appStore);
    const { t } = useTranslation();
    const classes = useStyles(state);
    const [isLoading, setIsLoading, row, setRow, errors, setErrors, updateProp] = useForm({ EventID: eventID, EventCommentID: 0, Description: '', User: null });

    const handleOnFocus = async () => {
        await AuthorizeFunction(() => console.log('auth'), null);
    }

    const handleChange = (e) => {
        console.log(e);
        var el = document.getElementById('scrollContainer');
        if (state.isMobile) {
            el.scrollTop = state.height / 3;
        }
        else {
            el.scrollTop = 0;
        }
        updateProp("Description", e.target.value)
    }

    const handleCancel = (e) => {
        updateProp("Description", '');
    }

    const postComment = async () => {
        try {
            setIsLoading(true);
            const result = await httpClient('post', 'Calendar/AddComment', objectToFormData(row));
            addComment(result);
            updateProp('Description', '');
        }
        catch (err) {
            console.log(err);
        }
        setIsLoading(false);
    };

    return (
        <Loader isLoading={isLoading}>

            <ListItem className={classes.TextField}
                disableGutters={state.isMobile}
            >
                {/* <ListItemAvatar>
                    <Avatar
                        alt={state.user ? state.user.userName : ''}
                        src={state.user ? state.user.picture : ''}
                        className={classes.smallAvatar}
                    />
                </ListItemAvatar> */}

                <ListItemText>
                    <TextField
                        label={t('common:comment')}
                        fullWidth
                        multiline={true}
                        autoFocus={false}
                        value={row.Description}
                        onChange={handleChange}
                        onFocus={handleOnFocus}
                    />
                </ListItemText>

            </ListItem>

            {
                row && row.Description ?
                    <ListItem
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                        dense
                        disableGutters={state.isMobile}>

                        <Fab color={'primary'} size="small">
                            <SaveIcon onClick={postComment} />
                        </Fab>

                        <Fab color={'secondary'} size="small">
                            <CancelIcon onClick={handleCancel} />
                        </Fab>

                    </ListItem> : ''
            }
        </Loader>
    )
}
export default EventCommentInput

import { useState } from 'react';

const useList = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [data, setData] = useState(props && props.data ? props.data : []);
    const [errors, setErrors] = useState([]);
    const [order, setOrder] = useState(props && props.order ? props.order : '');
    const [orderBy, setOrderBy] = useState(props && props.orderBy ? props.orderBy : '');

    return [isLoading, setIsLoading, data, setData, errors, setErrors, order, setOrder, orderBy, setOrderBy, hasMore, setHasMore];
};
export default useList;
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(2),
    },
}));

const Loader = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();

    if (!props.isLoading) {
        return props.children ? props.children : <></>
    }

    return (
        <div className={classes.root}>
            {t('actions:loading')}
            <LinearProgress color={props.color} />
        </div>
    );
}
export default Loader;

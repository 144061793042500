import React, { createContext, useReducer, useEffect } from 'react';

//state
const initialState = {
  user: null,
  isReady: false,
  height: 0,
  width: 0,
  isMobile: false,
  top: 0,
  navTitle: '',
  navDetail: '',
  claimTypes: [],
  league: null,
  nextGames: [],
  previousGames: [],
  calendarScrollTop: 0,
  summaryIndex: 0,
  navigatingAdmin: false,
};

const appStore = createContext(initialState);
const { Provider } = appStore;

//dispatch({ type: 'update user', payload: { ready: true, authenticated, user } });
const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {

    switch (action.type) {
      case 'updateIsMobile':
        return { ...state, height: action.payload.height, width: action.payload.width, isMobile: action.payload.isMobile };

      case 'updateHeight':
        return { ...state, top: action.payload.top };

      case 'updateUser':
        return { ...state, user: action.payload.user, isReady: action.payload.ready };

      case 'updateNavigation':
        return { ...state, navTitle: action.payload.navTitle, navDetail: action.payload.navDetail };

      case 'updateLeague':
        return { ...state, league: action.payload.league };

      case 'updateNextGames':
        return { ...state, nextGames: action.payload.games };

      case 'updatePreviousGames':
        return { ...state, previousGames: action.payload.games };

      case 'updateCalendarScrollTop':
        return { ...state, calendarScrollTop: action.payload.scrollTop };

      case 'updateSummaryIndex':
        return { ...state, summaryIndex: action.payload.index };

      case 'updateNavigatingAdmin':
        return { ...state, navigatingAdmin: action.payload};

      default:
        throw new Error();
    };
  }, initialState);

  useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight;
      const width = window.innerWidth;
      const isMobile = height > width;
      dispatch({ type: 'updateIsMobile', payload: { height, width, isMobile } });
    }
    handleResize();
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('league', JSON.stringify(state.league));
  }, [state.league]);

  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>
  );
};
export { appStore, StateProvider }
import React, { useContext, useEffect } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

//ui
import Container from '@material-ui/core/Container';

//custom
import AppNavBar from './AppNavBar';
import Home from './components/Home';
import Info from './components/home/Info';
import Admin from './components/Admin';
import AuthorizeRoute from './components/_shared/AuthorizeRoute';

//auth
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths, QueryParameterNames } from './components/api-authorization/ApiAuthorizationConstants';
import authService from './components/api-authorization/AuthorizeService';

import { appStore } from './appStore.js';

const App = (props) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(appStore);
  let subscription;

  const tabs = [
    { label: t('pages:admin'), path: '/admin', roles: ['QualityAdmin'] },
  ];
  const theme = createMuiTheme({
    overrides: {
      MuiListItemText: {
        primary: {
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        secondary: {
          fontSize: '75%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
      },
    },
    palette: {
      primary: {
        // light: '#ff7961',
        main: state.navigatingAdmin ? '#8B0000' : (state.league && state.league.mainColorCode ? state.league.mainColorCode : '#0665fb'),
        // dark: '#ff7961',
        contrastText: state.league && state.league.secondaryColorCode ? state.league.secondaryColorCode : '#fff',
      },
      secondary: {
        // light: '#ff7961',
        main: state.league && state.league.secondaryColorCode ? state.league.secondaryColorCode : '#71bf44',
        // dark: '#ff7961',
        contrastText: state.league && state.league.secondaryColorCode ? state.league.secondaryColorCode : '#fff',
      },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 10,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  });

  useEffect(() => {
    const init = async () => {
      subscription = authService.subscribe(() => populateAuthenticationState());
      await populateAuthenticationState();
    };
    init();
    return () => {
      authService.unsubscribe(subscription);
    }
  }, []);

  const populateAuthenticationState = async () => {
    let user = state.user;
    if (!user) {
      user = await authService.getUser();
      dispatch({ type: 'updateUser', payload: { user: user, isReady: true } });
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Router>

        <AppNavBar
          position="fixed"
        />

        <Container>

          <Switch>

            <Route exact path='/' render={(props) =>
              <Redirect to="/home" />
            } />

            <Route path='/home' render={(props) =>
              <Home {...props} />
            } />

            <AuthorizeRoute exact={false} path='/admin' component={Admin} noAccess={true} roles={['Admin']} />

            <Route path={ApplicationPaths.ApiAuthorizationPrefix} render={(props) =>
              <ApiAuthorizationRoutes {...props} />
            } />

            <Route render={(props) =>
              <div>Not found</div>
            } />

          </Switch>
        </Container>
      </Router>

    </ThemeProvider>
  );
}
export default App;

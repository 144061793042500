import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BackIcon from '@material-ui/icons/NavigateBefore';
import DeleteIcon from '@material-ui/icons/Delete';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';

import FormValidation from '../_shared/FormValidation';
import UserForm from './UserForm';
import UserRoles from './UserRoles';
import UserClaims from './UserClaims';
import ScrollBody from '../_shared/ScrollBody';
import httpClient from '../_shared/HttpClient';

const useStyles = makeStyles((theme) => ({
    fab: {
        marginBottom: theme.spacing(.5),
    },
    title: {
        fontSize: '75%',
    },
    heading: {
        fontSize: theme.spacing(2),
        fontWeight: 'bold',
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
}));

const User = (props) => {
    const { t } = useTranslation();
    const { id } = props.match.params;
    const classes = useStyles();
    const [expanded, setExpanded] = useState(id === '0' ? 'basics' : '');
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        console.log(title);
    }, [title]);

    // const handleDelete = async (e) => {
    //     e.preventDefault();

    //     if (isLoading) return;

    //     try {
    //         setIsLoading(true);
    //         const result = await httpClient('delete', 'User/remove', { id: id });
    //         if (result === true) {
    //             props.history.push('/admin/users');
    //         }
    //         else {
    //             if (result.errors) {
    //                 setErrors(result.errors);
    //                 setIsLoading(false);
    //             }
    //         }
    //     }
    //     catch (e) {
    //         setErrors([{ message: e }]);
    //         setIsLoading(false);
    //     }
    // }

    return (
        <>
        
            <FormValidation errors={errors} setErrors={setErrors} />

            <ScrollBody>

                <Accordion expanded={expanded === 'basics'} onChange={handleChange('basics')}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>
                            {t('common:basics')}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <UserForm
                            id={id}
                            setTitle={setTitle}
                            history={props.history}
                        />
                    </AccordionDetails>

                </Accordion>

                <Accordion expanded={expanded === 'roles'} onChange={handleChange('roles')}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>
                            {t('user:roles')}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <UserRoles
                            id={id}
                        />
                    </AccordionDetails>

                </Accordion>

                <Accordion expanded={expanded === 'claims'} onChange={handleChange('claims')}>

                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                    >
                        <Typography className={classes.heading}>
                            {t('user:claims')}
                        </Typography>
                    </AccordionSummary>

                    <AccordionDetails>
                        <UserClaims
                            id={id}
                        />
                    </AccordionDetails>

                </Accordion>


            </ScrollBody>
        </>
    )
}
export default User

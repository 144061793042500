import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
// import { fade } from '@material-ui/core/styles/colorManipulator';
import { makeStyles } from '@material-ui/core/styles';
import isEqual from 'lodash.isequal';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import OkIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Close';

import FormValidation from '../_shared/FormValidation';
import Loader from '../_shared/Loader';
import useForm from '../hooks/useForm';
import httpClient from '../_shared/HttpClient';

//store
import { appStore } from '../../appStore.js';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiTextField-root': {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        // backgroundColor: fade(theme.palette.primary.light, .1),
        // borderRadius: theme.spacing(1),
    },
    okButton: {
        color: theme.palette.info.main,
    },
    cancelButton: {
        color: theme.palette.error.main,
    },
    input: {
        display: "none",
    },
}));

const UserForm = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { state, dispatch } = useContext(appStore);
    const { id } = props;
    const [isLoading, setIsLoading, row, setRow, errors, setErrors, updateProp] = useForm();
    const [hasChanges, setHasChanges] = useState(false);
    const [prev, setPrev] = useState(null);


    useEffect(() => {
        loadData();
        return () => {
        }
    }, [id]);

    useEffect(() => {
        setHasChanges(!isEqual(prev, row));
    }, [row, prev]);

    const loadData = async () => {
        try {
            setIsLoading(true);
            var row = await httpClient('get', 'user/Get', { id: id });
            setRow(row);
            setPrev(row);

            dispatch({ type: 'updateNavigation', payload: { navTitle: t('actions:edit'), navDetail: row.userName } });
        }
        catch (e) {
            setErrors([{ message: e }]);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        try {
            setIsLoading(true);
            const result = await httpClient('post', 'user/Upsert', row);

            if (result.errors) {
                setErrors(result.errors);
                setIsLoading(false);
            }
            else {
                //set nav title if not new
                if (id !== '0') {
                    props.setTitle(row.name);
                    setRow(row);
                    setPrev(row);
                    setIsLoading(false);
                }
                else {
                    props.history.goBack();
                }
            }
        }
        catch (e) {
            setErrors([{ message: e }]);
            setIsLoading(false);
        }
    }

    return (
        <Loader isLoading={isLoading}>
            <Container className={classes.root}>

                <form onSubmit={handleSubmit} >

                    <FormValidation errors={errors} setErrors={setErrors} />

                    <Grid container>

                        <Grid item xs>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={row ? row.emailConfirmed : false}
                                        onChange={(e) => updateProp('emailConfirmed', !row.emailConfirmed)}
                                    />
                                }
                                label={t('user:emailConfirmed')}
                            />
                        </Grid>

                        <Grid item xs={'auto'}>
                            <IconButton
                                disabled={!hasChanges}
                                onClick={handleSubmit}
                                className={classes.okButton}>
                                <OkIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={'auto'}>
                            <IconButton
                                className={classes.cancelButton}
                                onClick={() => props.history.goBack()}
                            >
                                <CancelIcon />
                            </IconButton>
                        </Grid>

                    </Grid>

                </form>
            </Container>
        </Loader>
    )
}
export default UserForm
